/* App.js */
import React from 'react';
import CanvasJSReact from '../../canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Eyes = () => {
		const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			title:{
				text: "Eyes"
			},
            axisX:{
                labelAngle: 130,
                interval: 1,
            },
			axisY: {
				includeZero: true
			},
			data: [{
				type: "column", //change type to bar, line, area, pie, etc
				//indexLabel: "{y}", //Shows y value on all Data Points
				indexLabelFontColor: "#5A5757",
				indexLabelPlacement: "outside",
				dataPoints: [
					{ label: "Cute", y: 9.71, color: "#D9D9D9" },
					{ label: "Cute and blush", y: 9.71, color: "#D9D9D9" },
					{ label: "Closed", y: 8.28, color: "#70AC47" },
					{ label: "Closed and blush", y: 8.28, color: "#70AC47" },
					{ label: "Big", y: 6.95, color: "#6F9AE7" },
					{ label: "Big and blush", y: 6.95, color: "#6F9AE7" },
					{ label: "Funny", y: 6.95, color: "#6F9AE7" },
                    { label: "Funny and blush", y: 6.95, color: "#6F9AE7" },
					{ label: "Sunglasses", y: 4.14, color: "#9B72EE" },
					{ label: "Bandana", y: 4.14, color: "#9B72EE" },
					{ label: "3d glasses", y: 4.14, color: "#9B72EE" },
					{ label: "Blink", y: 4.14, color: "#9B72EE" },
					{ label: "Blink and blush", y: 4.14, color: "#9B72EE" },
					{ label: "Crossed out", y: 4.14, color: "#9B72EE" },
					{ label: "Blue laser eyes", y: 2.86, color: "#EC9D33" },
					{ label: "Red laser eyes", y: 2.86, color: "#EC9D33" },
					{ label: "Green laser eyes", y: 2.86, color: "#EC9D33" },
					{ label: "BURN BURN BURN", y: 2.86, color: "#EC9D33" },
				]
			}]
		}
		
		return (
		<div>
			<div className='w-[80px] h-[24px] flex ml-0 bg-white z-[1000000] relative top-[11px]'>
			</div>
			<CanvasJSChart id='charts' options = {options} />
			<div className='w-[75px] h-5 bg-white relative bottom-3'>
			</div>
		</div>
		);
	}


export default Eyes