/* App.js */
import React from 'react';
import CanvasJSReact from '../../canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Element = () => {
		const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			title:{
				text: "Element"
			},
            axisX:{
                labelAngle: 130,
                interval: 1,
            },
			axisY: {
				includeZero: true
			},
			data: [{
				type: "column", //change type to bar, line, area, pie, etc
				//indexLabel: "{y}", //Shows y value on all Data Points
				indexLabelFontColor: "#5A5757",
				indexLabelPlacement: "outside",
				dataPoints: [
					{ label: "Fire", y: 14.29, color: "#E0535F" },
					{ label: "Water", y: 14.29, color: "#6784FB" },
					{ label: "Poison", y: 14.29, color: "#B382FC" },
					{ label: "Electric", y: 14.29, color: "#FBE976" },
					{ label: "Frost", y: 14.29, color: "#A9E8EA" },
					{ label: "Rock", y: 14.29, color: "#FBA65D" },
					{ label: "Nature", y: 14.29, color: "#B1FC91" },
				]
			}]
		}
		
		return (
		<div>
			<div className='w-[80px] h-[24px] flex ml-0 bg-white z-[1000000] relative top-[11px]'>
			</div>
			<CanvasJSChart id='charts' options = {options} />
			<div className='w-[75px] h-5 bg-white relative bottom-3'>
			</div>
		</div>
		);
	}


export default Element