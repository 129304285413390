import ElementsSVG from '../components/ElementsSVG';
import { Link } from 'react-router-dom'
import bgimg from '../assets/images/elementsymbols/bg.png'
import { BsArrowLeft } from 'react-icons/bs'

const AllElements = () => {
    return (
        <div className='w-[100vw] h-[100vh] justify-center flex flex-col'>
            <img className='w-[100vw] h-[100vh] absolute z-[-1] bg-cover object-cover' src={bgimg} alt="Background image" />
            <div className='z-10 absolute flex my-auto left-[20%] right-[20%] w-[60%] h-[60%] 
            sm:left-[22.5%] sm:right-[22.5%] sm:w-[55%] sm:h-[55%]
            md:w-[45%] md:h-[45%] md:left-[27.5%] md:right-[27.5%]'>
                <ElementsSVG />
            </div>
            <div className='flex justify-center w-full h-full relative pt-[100px] sm:pt-[110px] md:pt-[125px] lg:pt-[140px]'>
                <Link className='items-center flex flex-row text-2xl h-[53px] shadow-md mx-auto py-2 px-5 bg-[#F0A657] border-b-[5px] border-[#C48747] active:translate-y-[3px] active:border-b-[2px] rounded-lg left-10' to='/'>
                    <BsArrowLeft className='mr-[3px]' />Back
                </Link>
                {/*
                <div className='flex flex-col w-[75%] sm:w-[65%] md:w-[60%] lg:w-[50%] xl:w-[40%]'>
                    <p className='text-2xl flex mx-auto pb-3 text-center'>
                        <span className='translate-y-[45px] sm:translate-y-[0px] lg:translate-y-[30px]'>
                            Choose one of the following elements to read more about them:
                            <span>
                                <a className='hover:brightness-[110%]' href="/elements/fire">
                                    <span style={{ color: '#E54E5A' }}> Fire, </span>
                                </a>
                                <a className='hover:brightness-[120%]' href="/elements/water">
                                    <span style={{ color: '#496BFF' }}>Water, </span>
                                </a>
                                <a className='hover:brightness-[110%]' href="/elements/poison">
                                    <span style={{ color: '#A566FF' }}>Poison, </span>
                                </a>
                                <a className='hover:brightness-[110%]' href="/elements/electric">
                                    <span style={{ color: '#FFEB59' }}>Electric, </span>
                                </a>
                                <a className='hover:brightness-[105%]' href="/elements/frost">
                                    <span style={{ color: '#8FECEF' }}>Frost, </span>
                                </a>
                                <a className='hover:brightness-[110%]' href="/elements/rock">
                                    <span style={{ color: '#FF993F' }}>Rock, </span>
                                </a>
                                <a className='hover:brightness-[115%]' href="/elements/nature">
                                    <span style={{ color: '#9EFF75' }}>and Nature.</span>
                                </a>
                            </span>
                        </span>
                    </p>
                </div>
                */}
            </div>
        </div>
    );
}

export default AllElements;