import { Link } from 'react-router-dom';
import house from '../assets/images/houseemoji.png';
import turtle from '../assets/images/turtleemoji.png';
import eyes from '../assets/images/eyesemoji.png';
import stats from '../assets/images/statsemoji.png';
import vote from '../assets/images/lightningemoji.png';
import gamecontroller from '../assets/images/gamecontrolleremoji.png';
import farm from '../assets/images/farmemoji.png'
import { AiOutlineLink } from "react-icons/ai";

function Header() {
    return (
        <header className='flex justify-center absolute top-0 sm:top-1 md:top-3 lg:top-5 z-50 w-full h-[100px]'>
            <div className='flex flex-row xs:w-[98%] w-[97%] sm:w-[94%] md:w-[90%] h-full'>
                <Link className='rounded-tl-lg rounded-bl-lg pt-1 md:py-2 my-auto w-[14.285%] bg-[rgb(50,94,241)] transition-all border-[rgb(34,72,177)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/'>
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] s:w-[36px] s:h-[36px] xs:w-[27px] xs:h-[27px] 2xs:w-[24px] 2xs:h-[24px] 3xs:w-[20px] 3xs:h-[20px] 4xs:w-[18px] 4xs:h-[18px]' src={house} alt="" />
                    <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl xs:text-sm 2xs:text-[0.72rem] 3xs:text-[0.64em] 4xs:text-[0.6em]'>Home</h1>
                </Link>

                <Link className='pt-1 md:py-2 my-auto w-[14.285%] bg-[rgba(54,151,44,1)] transition-all border-[rgba(44,124,37,1)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/mint'>
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] s:w-[36px] s:h-[36px] xs:w-[27px] xs:h-[27px] 2xs:w-[24px] 2xs:h-[24px] 3xs:w-[20px] 3xs:h-[20px] 4xs:w-[18px] 4xs:h-[18px]' src={turtle} alt="" />
                    <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl xs:text-sm 2xs:text-[0.72rem] 3xs:text-[0.64em] 4xs:text-[0.6em]'>Mint</h1>
                </Link>

                <Link className='pt-1 md:py-2 my-auto w-[14.285%] bg-[rgba(255,220,43,1)] transition-all border-[rgba(201,173,34,1)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/sea/all'>
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] s:w-[36px] s:h-[36px] xs:w-[27px] xs:h-[27px] 2xs:w-[24px] 2xs:h-[24px] 3xs:w-[20px] 3xs:h-[20px] 4xs:w-[18px] 4xs:h-[18px]' src={eyes} alt="" />
                    <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl xs:text-sm 2xs:text-[0.72rem] 3xs:text-[0.64em] 4xs:text-[0.6em]'>The sea</h1>
                </Link>

                <Link className='pt-1 md:py-2 my-auto w-[14.285%] bg-[rgba(255,43,43,1)] transition-all border-[rgba(207,36,36,1)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/stats'>
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] s:w-[36px] s:h-[36px] xs:w-[27px] xs:h-[27px] 2xs:w-[24px] 2xs:h-[24px] 3xs:w-[20px] 3xs:h-[20px] 4xs:w-[18px] 4xs:h-[18px]' src={stats} alt="" />
                    <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl xs:text-sm 2xs:text-[0.72rem] 3xs:text-[0.64em] 4xs:text-[0.6em]'>Stats</h1>
                </Link>

                <a className='pt-1 md:py-2 my-auto w-[14.285%] bg-[rgba(255,155,41,1)] transition-all border-[rgba(194,118,31,1)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' href="https://play.tubbyturtles.com/" target="_blank" rel="noreferrer">
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] s:w-[36px] s:h-[36px] xs:w-[27px] xs:h-[27px] 2xs:w-[24px] 2xs:h-[24px] 3xs:w-[20px] 3xs:h-[20px] 4xs:w-[18px] 4xs:h-[18px]' src={gamecontroller} alt="" />
                    <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl xs:text-sm 2xs:text-[0.72rem] 3xs:text-[0.64em] 4xs:text-[0.6em]'>Games <AiOutlineLink/></h1>
                </a>

                <a className='pt-1 md:py-2 my-auto w-[14.285%] bg-[rgba(179,86,255,1)] transition-all border-[rgba(141,69,201,1)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' href="https://vote.tubbyturtles.com/" target="_blank" rel="noreferrer">
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] s:w-[36px] s:h-[36px] xs:w-[27px] xs:h-[27px] 2xs:w-[24px] 2xs:h-[24px] 3xs:w-[20px] 3xs:h-[20px] 4xs:w-[18px] 4xs:h-[18px]' src={vote} alt="" />
                    <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl xs:text-sm 2xs:text-[0.72rem] 3xs:text-[0.64em] 4xs:text-[0.6em]'>Vote <AiOutlineLink/></h1>
                </a>

                <a className='rounded-tr-lg rounded-br-lg pt-1 md:py-2 my-auto w-[14.285%] bg-[rgb(86,179,255)] transition-all border-[rgb(69,153,221)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' href="https://farm.tubbyturtles.com/" target="_blank" rel="noreferrer">
                    <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] s:w-[36px] s:h-[36px] xs:w-[27px] xs:h-[27px] 2xs:w-[24px] 2xs:h-[24px] 3xs:w-[20px] 3xs:h-[20px] 4xs:w-[18px] 4xs:h-[18px]' src={farm} alt="" />
                    <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl xs:text-sm 2xs:text-[0.72rem] 3xs:text-[0.64em] 4xs:text-[0.6em]'>Farm <AiOutlineLink/></h1>
                </a>
            </div>
        </header>
    );
}

export default Header;