/* App.js */
import React from 'react';
import CanvasJSReact from '../../canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Shellbrim = () => {
		const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			title:{
				text: "Shellbrim"
			},
            axisX:{
                labelAngle: 130,
                interval: 1,
            },
			axisY: {
				includeZero: true
			},
			data: [{
				type: "column", //change type to bar, line, area, pie, etc
				//indexLabel: "{y}", //Shows y value on all Data Points
				indexLabelFontColor: "#5A5757",
				indexLabelPlacement: "outside",
				dataPoints: [
					{ label: "Red", y: 6.39, color: "#D9D9D9" },
					{ label: "Blue", y: 6.39, color: "#D9D9D9" },
					{ label: "Purple", y: 6.39, color: "#D9D9D9" },
					{ label: "yellow", y: 6.39, color: "#D9D9D9" },
					{ label: "Cyan", y: 6.39, color: "#D9D9D9" },
					{ label: "Orange", y: 6.39, color: "#D9D9D9" },
					{ label: "Green", y: 6.39, color: "#D9D9D9" },
                    { label: "Light red", y: 3.38, color: "#70AC47" },
					{ label: "Light blue", y: 3.38, color: "#70AC47" },
					{ label: "Light purple", y: 3.38, color: "#70AC47" },
					{ label: "Light yellow", y: 3.38, color: "#70AC47" },
					{ label: "Light cyan", y: 3.38, color: "#70AC47" },
					{ label: "Light orange", y: 3.38, color: "#70AC47" },
					{ label: "Light green", y: 3.38, color: "#70AC47" },
                    { label: "Dark red", y: 2.29, color: "#6F9AE7" },
					{ label: "Dark blue", y: 2.29, color: "#6F9AE7" },
					{ label: "Dark purple", y: 2.29, color: "#6F9AE7" },
					{ label: "Dark yellow", y: 2.29, color: "#6F9AE7" },
					{ label: "Dark cyan", y: 2.29, color: "#6F9AE7" },
					{ label: "Dark orange", y: 2.29, color: "#6F9AE7" },
					{ label: "Dark green", y: 2.29, color: "#6F9AE7" },
					{ label: "Black", y: 1.92, color: "#9B72EE" },
					{ label: "White", y: 1.92, color: "#9B72EE" },
					{ label: "Bowser shellbrim", y: 1.57, color: "#EC9D33" },
                    { label: "Frost", y: 1.57, color: "#EC9D33" },
				]
			}]
		}
		
		return (
		<div>
			<div className='w-[80px] h-[24px] flex ml-0 bg-white z-[1000000] relative top-[11px]'>
			</div>
			<CanvasJSChart id='charts' options = {options} />
			<div className='w-[75px] h-5 bg-white relative bottom-3'>
			</div>
		</div>
		);
	}


export default Shellbrim