import bgimg from '../assets/images/mintbg.png';
import { connect } from '../redux/blockchain/blockchainActions';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import { db, dbTwo } from '../firebase';
import { onValue, ref, get } from 'firebase/database';
import store from '../redux/store';
import twitterlogo from '../assets/images/twitterlogo.png';
import marketicon from '../assets/images/marketicon.png';
import telegramlogo from '../assets/images/telegramlogo.png';
import PulseLoader from 'react-spinners/PulseLoader';
import Fire from '../assets/images/elementsymbols/Fire.png';
import Water from '../assets/images/elementsymbols/Water.png';
import Poison from '../assets/images/elementsymbols/Poison.png';
import Electric from '../assets/images/elementsymbols/Electric.png';
import Frost from '../assets/images/elementsymbols/Frost.png';
import Rock from '../assets/images/elementsymbols/Rock.png';
import Nature from '../assets/images/elementsymbols/Nature.png';
import useColorChange from 'use-color-change';
import shelllogo from '../assets/images/shellcoin.png';
import ShlbalanceCount from '../components/ShlBalanceCount';
import carbonswapLogo from '../assets/images/carbonswapLogo.png';

function MyTurtles() {
    const dispatch = useDispatch();
    // blockchain contains the connection state of the blockchain
    const blockchain = useSelector((state) => state.blockchain);
    // data contains various variables such as: turtles a user owns/has staked, amount of approved SHL
    const data = useSelector((state) => state.data);
    console.log(data)

    // Get and update the data variables on every change of connected address
    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };
    useEffect(() => {
        getData();
        if (blockchain.account !== "") {
            dispatch(fetchData());
        }
    }, [blockchain.account, dispatch]);
    // --------------------------------------

    // Get the config variables one time from the config.json file
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        STAKINGCONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: ""
    });
    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };
    useEffect(() => {
        getConfig();
    }, []);
    // --------------------------------------

    // Start the Metamask connection by dispatching a connect request
    async function startApp() {
        window.ethereum.sendAsync({
            method: "eth_accounts",
            params: [],
            jsonrpc: "2.0",
            id: new Date().getTime()
        }, function (error, result) {
            if (result["result"] !== "") dispatch(connect());
        });
    }
    window.addEventListener('load', function () {
        startApp();
    })
    // --------------------------------------

    let totalStakePower = 0;
    const rarityArray = [42, 33, 45, 28, 53, 24, 61, 29, 43, 34, 29, 41, 42, 43, 42, 24, 55, 28, 54, 28,
        40, 46, 36, 48, 47, 29, 30, 37, 50, 26, 36, 43, 44, 36, 59, 31, 59, 20, 55, 34, 56, 48, 32, 44, 40, 29, 36, 27, 28, 20, 45, 33, 55, 29, 24, 29, 38, 45, 46, 26, 22, 39, 30, 47, 31, 26, 34, 26, 50, 33, 35, 55, 47, 55, 78, 52, 30, 42, 31, 27, 42, 50, 34, 48, 46, 60, 25, 32, 34, 30, 48, 52, 40, 40, 30, 52, 45, 30, 25, 28, 38,
        38, 24, 44, 41, 37, 57, 48, 53, 55, 29, 48, 29, 44, 37, 22, 29, 45, 25, 43, 68, 28, 50, 43, 36, 39, 52, 50, 34, 51, 24, 44, 46, 38, 37, 46, 47, 45, 43, 41, 34, 26, 53, 50, 24, 54, 52, 40, 24, 35, 31, 49, 34, 39, 46, 22, 38, 33, 72, 35, 48, 32, 42, 64, 31, 47, 25, 38, 44, 47, 34, 39, 41, 44, 40, 45, 44, 36, 40, 46, 32, 26,
        45, 41, 42, 37, 72, 42, 33, 33, 42, 43, 39, 26, 45, 39, 42, 51, 57, 43, 26, 30, 41, 36, 59, 43, 51, 32, 47, 58, 26, 47, 56, 28, 30, 32, 39, 56, 45, 29, 30, 38, 26, 32, 40, 27, 35, 57, 49, 24, 34, 49, 38, 44, 36, 33, 33, 33, 33, 32, 29, 43, 57, 42, 35, 25, 36, 33, 32, 40, 30, 32, 30, 34, 40, 43, 53, 48, 48, 22, 32, 49, 50,
        40, 38, 61, 41, 52, 35, 40, 48, 47, 79, 33, 52, 47, 36, 41, 49, 48, 37, 42, 40, 46, 32, 48, 49, 32, 44, 36, 32, 36, 46, 35, 30, 51, 57, 52, 28, 35, 46, 50, 46, 45, 49, 31, 47, 42, 22, 44, 52, 31, 36, 53, 56, 32, 30, 46, 37, 50, 52, 26, 34, 28, 48, 39, 29, 41, 26, 38, 46, 50, 70, 33, 22, 53, 40, 28, 39, 50, 41, 45, 50, 30,
        41, 40, 44, 44, 38, 36, 34, 40, 32, 29, 47, 28, 33, 43, 43, 51, 26, 31, 30, 26, 46, 64, 54, 54, 38, 34, 34, 38, 52, 28, 55, 52, 36, 39, 41, 58, 36, 71, 38, 57, 50, 28, 24, 42, 36, 54, 35, 33, 32, 22, 56, 24, 55, 30, 51, 62, 20, 42, 42, 45, 40, 48, 37, 38, 57, 40, 63, 41, 33, 51, 30, 38, 67, 49, 31, 60, 40, 29, 35, 42, 40,
        28, 45, 45, 44, 37, 36, 35, 34, 32, 40, 31, 45, 35, 41, 36, 52, 41, 66, 35, 36, 70, 47, 49, 43, 42, 65, 43, 34, 33, 52, 59, 62, 38, 52, 35, 43, 40, 52, 53, 27, 60, 49, 26, 20, 45, 36, 55, 40, 54, 36, 53, 30, 46, 22, 29, 40, 48, 48, 49, 32, 22, 28, 43, 26, 33, 26, 29, 67, 37, 35, 53, 31, 32, 76, 40, 54, 34, 26, 26, 33, 35,
        34, 51, 50, 42, 47, 41, 20, 40, 33, 41, 57, 32, 35, 40, 46, 25, 24, 28, 38, 54, 22, 39, 64, 39, 38, 39, 34, 41, 26, 27, 48, 36, 46, 32, 34, 38, 32, 42, 42, 26, 32, 40, 44, 32, 31, 52, 43, 55, 29, 32, 70, 52, 51, 32, 30, 22, 70, 50, 30, 54, 44, 30, 33, 32, 24, 51, 42, 43, 32, 42, 33, 35, 43, 42, 30, 38, 54, 38, 44, 52, 32,
        26, 42, 27, 38, 40, 48, 50, 27, 46, 54, 30, 44, 29, 49, 67, 51, 42, 35, 36, 28, 38, 34, 36, 35, 40, 52, 66, 47, 45, 28, 43, 54, 60, 28, 44, 52, 44, 57, 57, 36, 47, 28, 46, 44, 34, 44, 38, 49, 40, 36, 31, 26, 36, 42, 60, 32, 40, 52, 35, 29, 43, 31, 38, 42, 42, 30, 56, 40, 38, 24, 36, 24, 62, 38, 38, 66, 34, 65, 34, 32, 42,
        31, 35, 45, 42, 52, 43, 65, 43, 24, 33, 24, 38, 33, 64, 49, 41, 36, 38, 37, 49, 36, 26, 42, 44, 36, 57, 35, 54, 31, 44, 24, 42, 55, 40, 26, 45, 29, 49, 24, 29, 29, 28, 31, 29, 50, 32, 34, 39, 47, 36, 32, 39, 24, 76, 29, 36, 44, 58, 52, 25, 37, 30, 30, 33, 30, 24, 24, 52, 26, 22, 45, 36, 43, 29, 53, 33, 52, 53, 44, 51, 41,
        55, 43, 33, 40, 26, 56, 37, 50, 50, 36, 54, 34, 52, 31, 39, 36, 24, 42, 33, 51, 40, 37, 52, 35, 26, 57, 34, 68, 43, 34, 36, 43, 30, 62, 39, 67, 50, 40, 32, 32, 27, 48, 32, 37, 37, 40, 43, 38, 30, 40, 44, 36, 34, 36, 48, 40, 55, 47, 38, 30, 40, 35, 33, 32, 62, 35, 38, 31, 36, 40, 28, 43, 57, 52, 43, 41, 43, 20, 30, 37, 39,
        43, 56, 31, 42, 45, 47, 51, 41, 34, 24, 34, 45, 38, 64, 26, 60, 29, 42, 41, 39, 38, 65, 51, 40, 29, 39, 52, 37, 40, 31, 35, 42, 53, 33, 31, 32, 42, 43, 22, 41, 24, 29, 41, 22, 41, 31, 35, 38, 46, 48, 24, 54, 46, 22, 32, 32, 63, 42, 24, 31, 37, 50, 43, 53, 60, 45, 36, 35, 54, 54, 35, 37, 22, 20, 50, 28, 32, 43, 56, 29, 27,
        45, 40, 43, 55, 35, 52, 58, 57, 36, 45, 39, 42, 34, 36, 38, 45, 31, 36, 34, 36, 39, 39, 39, 39, 32, 85, 22, 51, 54, 39, 28, 45, 33, 45, 64, 40, 42, 39, 37, 44, 40, 39, 55, 42, 29, 36, 30, 36, 60, 57, 67, 70, 26, 28, 48, 48, 40, 54, 34, 30, 26, 59, 33, 43, 60, 47, 52, 35, 43, 45, 33, 32, 22, 45, 32, 54, 34, 38, 44, 45, 31,
        33, 55, 27, 60, 45, 32, 18, 32, 36, 28, 42, 48, 37, 32, 22, 35, 28, 59, 66, 50, 43, 36, 28, 33, 31, 46, 40, 41, 35, 49, 38, 28, 26, 33, 36, 33, 35, 47, 42, 40, 40, 38, 47, 26, 24, 45, 37, 38, 39, 30, 39, 30, 37, 41, 66, 24, 24, 39, 47, 20, 29, 38, 26, 32, 40, 41, 47, 30, 41, 45, 57, 26, 41, 51, 30, 57, 36, 55, 38, 48, 34,
        51, 54, 41, 38, 40, 31, 59, 48, 24, 46, 30, 45, 38, 29, 50, 42, 36, 22, 37, 45, 54, 44, 42, 40, 29, 18, 32, 32, 44, 52, 48, 46, 22, 34, 42, 45, 51, 60, 50, 22, 38, 35, 47, 37, 44, 22, 40, 36, 43, 44, 36, 36, 36, 22, 50, 48, 48, 51, 39, 28, 43, 44, 34, 50, 37, 34, 54, 36, 37, 62, 22, 20, 46, 35, 50, 28, 28, 45, 24, 39, 44,
        28, 30, 68, 46, 61, 34, 50, 46, 58, 42, 29, 34, 30, 46, 45, 54, 43, 44, 26, 57, 46, 29, 53, 26, 49, 26, 34, 22, 45, 45, 52, 38, 38, 44, 34, 48, 38, 38, 22, 40, 29, 48, 50, 60, 52, 36, 26, 26, 47, 45, 48, 25, 53, 31, 32, 38, 44, 31, 36, 39, 46, 29, 49, 51, 55, 53, 69, 52, 50, 34, 51, 48, 46, 55, 33, 38, 58, 26, 39, 38, 44,
        43, 22, 42, 42, 46, 43, 32, 67, 39, 31, 28, 48, 44, 52, 32, 54, 28, 74, 56, 37, 26, 31, 38, 28, 39, 45, 53, 32, 68, 32, 42, 49, 34, 24, 53, 30, 33, 48, 26, 31, 50, 26, 49, 52, 44, 37, 40, 24, 38, 40, 36, 33, 32, 44, 32, 24, 56, 49, 49, 41, 51, 41, 39, 40, 60, 28, 31, 34, 48, 32, 39, 46, 38, 55, 50, 38, 38, 56, 54, 31, 42,
        44, 46, 32, 22, 41, 49, 29, 51, 36, 43, 60, 26, 28, 50, 35, 34, 32, 32, 36, 35, 37, 40, 38, 42, 43, 32, 29, 48, 44, 43, 36, 41, 42, 38, 22, 54, 36, 24, 32, 57, 28, 40, 37, 31, 50, 25, 38, 35, 38, 31, 34, 34, 32, 59, 30, 28, 22, 22, 24, 27, 55, 35, 47, 27, 33, 34, 42, 36, 36, 24, 34, 29, 31, 36, 51, 52, 30, 35, 24, 24, 50,
        59, 30, 37, 46, 33, 27, 56, 45, 45, 28, 28, 31, 42, 45, 40, 36, 53, 42, 47, 34, 35, 59, 46, 47, 44, 53, 25, 34, 33, 30, 22, 34, 22, 39, 36, 36, 32, 35, 48, 26, 36, 30, 57, 49, 72, 34, 28, 68, 34, 40, 33, 49, 45, 38, 37, 52, 45, 37, 39, 36, 55, 39, 46, 32, 44, 30, 36, 40, 49, 51, 31, 45, 37, 58, 30, 53, 32, 41, 36, 28, 43,
        40, 24, 52, 41, 58, 30, 33, 40, 24, 28, 31, 26, 65, 24, 24, 36, 43, 46, 28, 26, 36, 50, 34, 54, 55, 37, 38, 52, 34, 42, 35, 24, 45, 37, 43, 41, 38, 44, 47, 42, 36, 50, 24, 48, 35, 34, 39, 47, 53, 55, 40, 39, 50, 30, 41, 39, 31, 28, 31, 26, 38, 49, 46, 49, 22, 55, 26, 29, 62, 53, 31, 30, 32, 34, 42, 30, 29, 37, 60, 47, 46,
        31, 49, 43, 36, 52, 56, 40, 25, 36, 38, 44, 36, 42, 36, 41, 30, 62, 30, 39, 56, 42, 29, 24, 33, 36, 37, 40, 38, 51, 38, 32, 55, 62, 24, 40, 39, 34, 36, 35, 33, 50, 62, 46, 35, 38, 36, 48, 43, 42, 25, 34, 53, 30, 50, 36, 32, 41, 45, 40, 60, 39, 33, 37, 38, 31, 43, 56, 62, 32, 33, 40, 26, 46, 37, 22, 35, 39, 41, 54, 41, 54,
        40, 39, 32, 32, 35, 34, 38, 77, 38, 59, 48, 50, 38, 26, 53, 35, 65, 68, 54, 43, 64, 65, 44, 55, 41, 65, 29, 24, 36, 36, 41, 28, 45, 54, 38, 46, 43, 54, 24, 34, 41, 61, 46, 20, 53, 44, 46, 26, 42, 22, 55, 42, 36, 50, 24, 48, 32, 35, 26, 30, 45, 34, 32, 42, 52, 44, 36, 32, 36, 47, 30, 46, 28, 43, 36, 30, 44, 58, 49, 38, 47,
        64, 22, 38, 30, 41, 33, 50, 28, 46, 26, 38, 20, 53, 39, 35, 31, 39, 43, 37, 43, 28, 26, 29, 44, 30, 34, 57, 54, 36, 37, 32, 28, 29, 41, 35, 44, 36, 46, 29, 36, 44, 52, 37, 31, 56, 39, 72, 24, 58, 36, 53, 35, 29, 42, 30, 39, 36, 28, 30, 50, 81, 45, 26, 32, 47, 40, 35, 34, 25, 30, 44, 62, 39, 42, 51, 38, 44, 36, 60, 45, 26,
        29, 52, 28, 30, 33, 32, 40, 38, 48, 50, 54, 47, 41, 44, 51, 41, 36, 34, 35, 36, 55, 33, 32, 34, 38, 35, 60, 34, 64, 55, 57, 43, 41, 51, 45, 29, 44, 35, 28, 47, 70, 55, 38, 38, 60, 34, 44, 31, 44, 34, 40, 45, 33, 34, 35, 32, 22, 31, 52, 58, 27, 40, 37, 54, 38, 38, 55, 24, 54, 26, 54, 38, 34, 48, 36, 40, 30, 43, 46, 28, 34,
        53, 34, 36, 27, 49, 28, 48, 25, 43, 43, 40, 48, 53, 39, 44, 47, 48, 41, 31, 26, 42, 30, 24, 40, 45, 43, 30, 34, 56, 48, 40, 44, 51, 50, 52, 62, 35, 50, 58, 48, 33, 26, 46, 39, 26, 54, 26, 33, 27, 48, 55, 40, 53, 54, 41, 40, 46, 35, 46, 24, 60, 47, 53, 40, 34, 47, 51, 46, 43, 36, 33, 40, 43, 46, 34, 30, 43, 56, 58, 58, 28,
        44, 20, 24, 27, 33, 32, 45, 38, 63, 36, 36, 39, 35, 35, 26, 34, 62, 39, 42, 47, 50, 49, 30, 28, 28, 45, 43, 38, 41, 30, 37, 54, 25, 61, 41, 45, 32, 36, 40, 33, 46, 34, 30, 18, 54, 58, 28, 62, 34, 52, 58, 39, 39, 38, 41, 33, 61, 57, 26, 52, 46, 49, 37, 46, 54, 53, 50, 32, 34, 44, 41, 30, 45, 22, 36, 29, 55, 48, 40, 41, 26,
        40, 24, 53, 48, 26, 29, 26, 31, 34, 42, 38, 40, 43, 64, 40, 29, 26, 44, 49, 35, 34, 37, 33, 30, 35, 42, 45, 62, 34, 43, 26, 30, 37, 40, 35, 36, 44, 27, 48, 50, 28, 26, 52, 30, 45, 41, 62, 38, 38, 41, 35, 43, 42, 50, 51];
    if (data.turtlesStaked.length !== 0) {
        for (let e = 0; e < (data.turtlesStaked).length; e++) {
            const element = data.turtlesStaked[e];
            totalStakePower = totalStakePower + rarityArray[parseInt(element) - 1]
        }
        totalStakePower *= 5;
    }

    const [currentSupply, setCurrentSupply] = useState(0);
    const [approveAmount, setApproveAmount] = useState(0);
    useEffect(() => {
        setCurrentSupply(data.totalSupply)
        if ((parseInt(data.approveContractAllowance) / 1e+18 / 5000) < 1) {
            setApproveAmount(0)
        }
        else {
            setApproveAmount(parseInt(data.approveContractAllowance) / 1e+18 / 5000)
        }
    }, [data]);

    function getElementTurtleIcon(input) {
        if (input === 'Fire') { return Fire }
        if (input === 'Water') { return Water }
        if (input === 'Poison') { return Poison }
        if (input === 'Electric') { return Electric }
        if (input === 'Frost') { return Frost }
        if (input === 'Rock') { return Rock }
        if (input === 'Nature') { return Nature }
    }

    const [SHLPriceEWT, setSHLPriceEWT] = useState(0)
    const [SHLPriceUSD, setSHLPriceUSD] = useState(0)
    const [EWTPriceInUSD, setEWTPriceInUSD] = useState(0)
    const [avgTurtlePrice, setAvgTurtlePrice] = useState(0)

    async function getPriceDataDB() {
        let SHLEWTamount = await get(ref(dbTwo, '/' + 'SHLPriceEWT'));
        SHLEWTamount = SHLEWTamount["_node"]["value_"];
        SHLEWTamount = Number(SHLEWTamount);
        setSHLPriceEWT(SHLEWTamount);

        let EWTPriceUSD = await get(ref(dbTwo, '/' + 'EWTPriceUSD'));
        EWTPriceUSD = EWTPriceUSD["_node"]["value_"];
        EWTPriceUSD = Number(EWTPriceUSD);
        setEWTPriceInUSD(EWTPriceUSD);

        let SHLUSDamount = await get(ref(dbTwo, '/' + 'SHLPriceUSD'));
        SHLUSDamount = SHLUSDamount["_node"]["value_"];
        SHLUSDamount = Number(SHLUSDamount);
        setSHLPriceUSD(SHLUSDamount);

        let avgTurtlePrice = await get(ref(dbTwo, '/' + 'avgMint'));
        avgTurtlePrice = avgTurtlePrice["_node"]["value_"];
        avgTurtlePrice = Number(avgTurtlePrice);
        setAvgTurtlePrice(avgTurtlePrice);
    }

    useEffect(() => {
        getPriceDataDB();
    }, []);

    const [Turtles, setTurtles] = useState([])
    const [DBTurtles, setDBTurtles] = useState([])
    useEffect(() => {
        onValue(ref(db), snapshot => {
            const firebasedata = snapshot.val();
            setDBTurtles(firebasedata);
        })
    }, [blockchain.account]);

    function updateUserOwned() {
        let tokensHolderOwns = data["turtleOwner"];
        let tokensStaked = data["turtlesStaked"];

        let newturtleslist = []
        if (tokensHolderOwns !== []) {
            if (DBTurtles !== []) {
                for (let g = 0; g < DBTurtles.length; g++) {
                    for (let h = 0; h < tokensHolderOwns.length; h++) {
                        const element = tokensHolderOwns[h];
                        try {
                            if (DBTurtles !== [] && DBTurtles !== undefined) {
                                if (parseInt(element) === DBTurtles[g].tokenId) {
                                    newturtleslist.push(DBTurtles[g])
                                }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            }
        }
        if (tokensStaked !== []) {
            if (DBTurtles !== []) {
                for (let g = 0; g < DBTurtles.length; g++) {
                    for (let v = 0; v < tokensStaked.length; v++) {
                        let element = tokensStaked[v];
                        try {
                            if (DBTurtles !== [] && DBTurtles !== undefined) {
                                if (parseInt(element) === DBTurtles[g].tokenId) {
                                    newturtleslist.push(DBTurtles[g])
                                }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }
            }
        }
        setTurtles(newturtleslist)
    }

    useEffect(() => {
        updateUserOwned();
        stakedOrNot();
    }, [blockchain.account, store.getState()["data"]["turtleOwner"], store.getState()["data"]["turtlesStaked"]]);

    function approveShellsToTubbyTurtles() {
        let approveAmountStaking = '150000000000000000000000';
        let cost = 0;
        let totalCostWei = String(cost);
        blockchain.stakingSmartContract.methods.approve(CONFIG.STAKINGCONTRACT_ADDRESS, approveAmountStaking)
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
    }

    async function stakeTurtle(tokenId) {
        let allstakebuttons = document.getElementsByClassName('stakebutton')
        let allstaketext = document.getElementsByClassName('staketext')
        let allstakeimage = document.getElementsByClassName('stakeimage')
        let alltokenids = document.getElementsByClassName('turtletokenid')
        let allunstakebuttons = document.getElementsByClassName('unstakebutton')

        console.log(`staking turtle with id: ${tokenId}...`)
        blockchain.stakingSmartContract.methods.stakeByIds([tokenId])
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account
            }).then((receipt) => {
                let returnvalue = tokenId
                for (let index = 0; index < alltokenids.length; index++) {
                    if (parseInt(returnvalue) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allstakebuttons[index];
                        element.disabled = true;
                        element.style.backgroundColor = '#D1F9E0'
                        element.style.borderColor = '#BBF2CF'

                        let element2 = allstaketext[index];
                        element2.innerText = 'Staked'

                        let element3 = allstakeimage[index];
                        element3.src = ''
                        element3.style.width = '0px'
                        element3.style.height = '0px'
                        element3.style.marginLeft = '0px'
                    }
                }
                let returnvalue2 = tokenId
                for (let index = 0; index < alltokenids.length; index++) {
                    if (parseInt(returnvalue2) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allunstakebuttons[index];
                        element.innerText = 'Unstake'
                        element.disabled = false;
                        element.style.backgroundColor = '#FCA5A5'
                        element.style.borderColor = '#F87171'
                    }
                }
            }
            )
    }

    async function unstakeTurtle(tokenId) {
        let allapprovebuttons = document.getElementsByClassName('approvebutton')
        let allstakebuttons = document.getElementsByClassName('stakebutton')
        let allstaketext = document.getElementsByClassName('staketext')
        let allstakeimage = document.getElementsByClassName('stakeimage')
        let allunstakebuttons = document.getElementsByClassName('unstakebutton')
        let alltokenids = document.getElementsByClassName('turtletokenid')

        console.log(`unstaking turtle with id: ${tokenId}...`)
        blockchain.stakingSmartContract.methods.unstakeByIds([tokenId])
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account
            }).then((receipt) => {
                let returnvalue = tokenId
                for (let index = 0; index < alltokenids.length; index++) {
                    if (parseInt(returnvalue) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allstakebuttons[index];
                        element.disabled = false;
                        element.style.backgroundColor = '#86EFAC'
                        element.style.borderColor = '#4ADE80'

                        let element2 = allstaketext[index];
                        element2.innerText = 'Stake'

                        let element3 = allstakeimage[index];
                        element3.src = shelllogo
                        element3.style.width = '1rem'
                        element3.style.height = '1rem'
                        element3.style.marginLeft = '3px'

                        let element20 = allapprovebuttons[index];
                        element20.innerText = 'Approve'
                        element20.disabled = false;
                        element20.style.backgroundColor = '#93C5FD'
                        element20.style.borderColor = '#60A5FA'
                    }
                }
                let returnvalue2 = tokenId
                for (let index = 0; index < alltokenids.length; index++) {
                    if (parseInt(returnvalue2) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allunstakebuttons[index];
                        element.innerText = 'Unstaked'
                        element.disabled = true;
                        element.style.backgroundColor = '#FDD9D9'
                        element.style.borderColor = '#FCC4C4'
                    }
                }
            }
            )
    }

    async function approveTurtle(tokenId) {
        let allapprovebuttons = document.getElementsByClassName('approvebutton')
        let allstakebuttons = document.getElementsByClassName('stakebutton')
        let alltokenids = document.getElementsByClassName('turtletokenid')

        blockchain.smartContract.methods.approve(CONFIG.STAKINGCONTRACT_ADDRESS, tokenId)
            .send({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account
            }).then((receipt) => {
                let returnvalue = parseInt(receipt["events"]["Approval"]["returnValues"][2])
                for (let index = 0; index < alltokenids.length; index++) {
                    if ((returnvalue) === parseInt(alltokenids[index].innerHTML.substring(1))) {
                        let element = allapprovebuttons[index];
                        element.innerText = 'Approved'
                        element.disabled = true;
                        element.style.backgroundColor = '#DBEBFE'
                        element.style.borderColor = '#CAE1FD'

                        let element2 = allstakebuttons[index];
                        element2.disabled = false;
                        element2.style.backgroundColor = '#86EFAC'
                        element2.style.borderColor = '#4ADE80'
                    }
                }
            }
            )
    }

    async function stakedOrNot() {
        let tokensStaked = data["turtlesStaked"];
        let allstakebuttons = document.getElementsByClassName('stakebutton')
        let allunstakebuttons = document.getElementsByClassName('unstakebutton')
        let allstaketext = document.getElementsByClassName('staketext')
        let allstakeimage = document.getElementsByClassName('stakeimage')
        let alltokenids = document.getElementsByClassName('turtletokenid')
        let allapprovebuttons = document.getElementsByClassName('approvebutton')

        for (let index2 = 0; index2 < alltokenids.length; index2++) {
            for (let index3 = 0; index3 < tokensStaked.length; index3++) {
                if (alltokenids[index2].innerText.substring(1) === tokensStaked[index3]) {
                    let element7 = allstakebuttons[index2];
                    element7.disabled = true;
                    element7.style.backgroundColor = '#D1F9E0'
                    element7.style.borderColor = '#BBF2CF'
                    let element8 = allstaketext[index2];
                    element8.innerText = 'Staked';
                    element8.disabled = true;
                    let element13 = allstakeimage[index2];
                    element13.disabled = true;
                    element13.src = ''
                    element13.style.width = '0px'
                    element13.style.height = '0px'
                    element13.style.marginLeft = '0px'
                    let element6 = allapprovebuttons[index2];
                    element6.innerText = 'Approved'
                    element6.disabled = true;
                    element6.style.backgroundColor = '#DBEBFE'
                    element6.style.borderColor = '#CAE1FD'
                }
            }
        }
    }

    useEffect(() => {
        stakedOrNot()
    }, [Turtles]);

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        if (data["loading"] === false) {
            setLoading(false)
        }
        getStakedAmount()
    }, [data, blockchain]);

    const [stakedamount, setStakedamount] = useState(0)
    function getStakedAmount() {
        setStakedamount(data["totalstakedamount"])
    }

    window.addEventListener('load', function () {
        setInterval(function () {
            let allturtlecards = document.getElementsByClassName('nftcard')
            if (allturtlecards.length > 400) {
                window.location.reload();
            }
        }, 1000);
    })

    const [earned, setEarned] = useState(0);
    async function getEarnedRewards() {
        try {
            let earnedShells = await store
                .getState()
                .blockchain.stakingSmartContract.methods.getAllRewards(blockchain.account)
                .call();
            setEarned((earnedShells / 1000000000000000000).toFixed(2))
        } catch (error) {
            return;
        }
    }

    async function claimAllStakingRewards() {
        blockchain.stakingSmartContract.methods.claimAll()
            .send({
                to: CONFIG.STAKINGCONTRACT_ADDRESS,
                from: blockchain.account
            }).then((receipt) => {
                console.log(receipt)
            })
    }

    setInterval(function () {
        getEarnedRewards()
    }, 4000);

    const colorStyle = useColorChange(earned, {
        higher: "limegreen",
        lower: "crimson",
        duration: 2200
    });

    return (
        <div className="flex flex-col mx-auto w-[96%] md:w-[88%] lg:w-[83%] xl:w-[76%] h-[calc(100vh-100px)] mt-[95px] sm:mt-[110px] md:mt-[135px] lg:mt-[160px] mb-6 sm:mb-12 md:mb-20 lg:mb-28">
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={bgimg} alt="the background image" />

            <div className='rounded-2xl flex flex-col bg-[rgb(231,241,255)] mb-4 sm:mb-7 md:mb-10 lg:mb-12 xl:mb-14'>
                <div className='rounded-2xl w-full h-[75px] xs:h-[65px] 2xs:h-[55px] 3xs:h-[50px] 4xs:h-[45px] flex flex-row'>
                    <Link to='/sea/all' className='rounded-tl-2xl bg-[rgb(201,226,255)] font-bold w-1/2 h-full justify-center flex items-center text-2xl s:text-xl xs:text-lg'>
                        All turtles
                    </Link>
                    <Link to='/sea/my' className='rounded-tr-2xl bg-[rgb(231,241,255)] font-bold w-1/2 h-full justify-center flex items-center text-2xl s:text-xl xs:text-lg'>
                        My turtles
                    </Link>
                </div>
                <div>
                    {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                        <div className='flex flex-col justify-center'>
                            <div className='h-[70px]'>
                                <button className='mt-3 mb-1 flex pl-1 pr-2 mx-auto justify-center rounded-xl items-center
                            bg-[#45e07e] border-[#28be5f] border-b-[3px] md:border-b-[4px] hover:brightness-110 md:active:border-b-[2px] md:active:translate-y-[2px] active:border-b-[1px] active:translate-y-[2px]' onClick={(e) => {
                                        e.preventDefault();
                                        dispatch(connect());
                                        getData();
                                    }}>

                                    <svg width="50px" height="50px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                    </svg>
                                    Connect
                                </button>
                            </div>

                            {blockchain.errorMsg !== "" ? (
                                <>
                                    <h2 className='text-center mb-2 errormsg'>{blockchain.errorMsg}</h2>
                                </>
                            ) : null}
                        </div>
                    ) : (
                        <div className='px-5 2xs:px-4 3xs:px-3 flex justify-center flex-col md:w-[90%] lg:w-[80%] mx-auto'>
                            <div className='flex w-full'>
                                <a className='hover:brightness-110 translate-y-4 h-[34px] ml-0 mr-auto pr-2 items-center flex flex-row rounded-lg bg-blue-300' href='https://carbonswap.exchange//#/swap?inputCurrency=EWT&outputCurrency=0xc6c6239614723298591f16bb2f779c9199b5ab1a' target="_blank" rel="noreferrer"><img className='w-[24px] h-[24px] ml-2 mr-1' src={carbonswapLogo} />Carbonswap</a>
                                <a className='hover:brightness-110 translate-y-4 h-[34px] ml-auto mr-0 pr-2 items-center flex flex-row rounded-lg bg-blue-300' href='https://info.carbonswap.exchange/token/0xc6c6239614723298591f16bb2f779c9199b5ab1a' target="_blank" rel="noreferrer"><img className='w-[24px] h-[24px] ml-2 mr-1' src={shelllogo} />Token info</a>
                            </div>

                            <ShlbalanceCount />

                            <h2 className='text-lg s:text-base xs:text-sm text-center'>
                                Sending a turtle on a sea adventure costs <span className='font-bold'>5.000 SHL
                                </span>
                                <span>
                                    <img className='w-[20px] h-[20px] ml-1 my-auto -translate-y-[2px] inline' alt="SeaShell token logo" src={shelllogo}></img>
                                </span>
                            </h2>

                            <div className='my-2 pb-2 flex flex-col'>
                                <div className='mt-2 mx-auto s:h-[38px] h-[46px]'>
                                    <button className='bg-[#45e07e] border-[#28be5f] hover:brightness-110 mt-1 border-b-[4px] active:border-b-[2px] active:translate-y-[2px] s:text-sm text-base sm:text-lg rounded-xl py-[3px] px-[6px] flex flex-row items-center' onClick={approveShellsToTubbyTurtles}>Approve SeaShells<img className='ml-[5px] my-auto xs:w-4 xs:h-4 s:w-5 s:h-5 w-6 h-6' alt="SeaShell token logo" src={shelllogo} /></button>
                                </div>
                                {Math.floor(approveAmount) < 5 && (
                                <div className='flex'>
                                    <p className='text-center s:text-sm text-base sm:text-lg mx-auto'>You can still send <span className='font-bold'>{Math.floor(approveAmount)}</span> more turtles on a sea adventure before you need to approve more SHL</p>
                                </div>)}
                            </div>

                            <div className='h-[46px] sm:h-[52px] mx-auto bg-green-200 rounded-lg py-[8px] px-3 flex flex-row'>
                                <p className='s:text-[0.8rem] xs:text-xs 2xs:text-[0.7rem] 3xs:text-[0.65rem] text-sm sm:text-xl my-auto'>SeaShells found by your turtles: <span className='font-bold' id='claimableamount' style={colorStyle}>{earned}</span><img className='ml-1 inline -translate-y-[2px] w-[16px] h-[16px] sm:w-[22px] sm:h-[22px]' src={shelllogo} /></p>
                                <button onClick={claimAllStakingRewards} className='xs:text-[0.85rem] text-base my-auto items-center sm:text-xl rounded-xl ml-2 py-1 px-3 flex justify-center h-full bg-[#45e07e] border-[#28be5f] hover:brightness-110 border-b-[3px] active:border-b-[1px] active:translate-y-[2px]'>Collect all</button>
                            </div>
                            <div className='flex'>
                                <p className='s:text-[0.8rem] xs:text-xs text-sm sm:text-xl mx-auto'>Daily rewards: <span
                                    className='font-bold'>{totalStakePower}
                                </span> SHL/day
                                </p>
                            </div>
                            <div className='flex'>
                                <p className='s:text-[0.8rem] xs:text-xs text-sm sm:text-xl mx-auto'>Your estimated <abbr className='hover:invert-[50%]' title="*Based on the average Tubby Turtle mint price and the rarity scores of your turtles">APY*</abbr>: <span
                                    className='font-bold'>{(((totalStakePower * SHLPriceUSD) / ( (avgTurtlePrice*EWTPriceInUSD) * data.turtlesStaked.length)) * 100 * 365).toFixed(2)}
                                </span>%
                                </p>
                            </div>

                            <div className='flex mx-auto mt-2 mb-2'>
                                <div className='2xs:text-base xs:text-lg text-xl mr-2 flex flex-col text-center'>
                                    <p className='font-bold'>Turtles on a sea adventure:</p>
                                    <p>{stakedamount}/{currentSupply}</p>
                                    <p>{(stakedamount / currentSupply * 100).toFixed(2)}%</p>
                                </div>
                            </div>

                        </div>
                    )}


                        <div>
                            {loading === true && data !== "" && data.turtleOwner === [] ?
                                <div className='flex flex-col justify-center items-center mb-4'>
                                    <p className='my-2 text-xl'>Please wait, loading in your turtles...</p>
                                    <PulseLoader loading={loading} color="#4d7cff" size={25} />
                                </div>
                                : (
                                    <div className='nftcontainer flex flex-wrap justify-center rounded-xl p-4 mt-2 bg-[rgb(201,226,255)]'>
                                        {(Turtles.map(turtle => (
                                            <div className='nftcard hover:scale-[1.08] transition-all w-[200px] bg-white rounded-xl m-4'>
                                                <Link to={`/${turtle.tokenId}`}>
                                                    <img className='elementicon absolute w-16 -translate-x-4 -translate-y-5 rounded-full' src={getElementTurtleIcon(turtle.attributes[1]["value"])} alt="Turtle element icon" />
                                                </Link>
                                                <Link to={`/${turtle.tokenId}`}>
                                                    <img className='nftimg w-[calc(100%-16px)] h-auto rounded-xl flex mx-auto m-2' src={turtle.image} alt="Tubby Turtle" />
                                                </Link>
                                                <div className='flex flex-col'>
                                                    <div className='nftinfocardtext'>
                                                        <h2 value={turtle.tokenId} className='turtletokenid text-center text-base sm:text-lg md:text-xl'>#{turtle.tokenId}</h2>
                                                        {(turtle.name).includes('Tubby Turtle') === true ? (
                                                        <h2 className='mt-[2px] text-[rgb(90,90,90)] turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[1em]'>{turtle.name}</h2>
                                                        ) : (<h2 className='mt-[2px] bg-[rgb(199,223,255)] border-2 border-[rgb(159,201,255)] xs:mx-[2px] s:mx-[3px] mx-[5px] sm:mx-[7px] md:mx-[9px] rounded-lg turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[1em]'>{turtle.name}</h2>)}
                                                    </div>
                                                    <div className='flex flex-col mt-1 mb-1'>
                                                        <div>
                                                            <button onClick={() => approveTurtle(`${turtle.tokenId}`)} className='approvebutton mx-auto w-1/2 mt-1 xs:mt-[0.15rem] 2xs:mt-[0.1rem] 3xs:mt-[0.05rem] flex justify-center text-center items-center xs:text-[0.85em] 2xs:text-[0.7em] 3xs:text-[0.65em] 4xs:text-[0.6em] rounded-md h-[25px] bg-blue-300 hover:bg-[rgb(163,206,255)] border-b-[3px] border-blue-400 active:border-b-[1px] active:translate-y-[2px]'>
                                                                Approve
                                                            </button>
                                                        </div>
                                                        <div className='flex flex-row'>
                                                            <button onClick={() => stakeTurtle(`${turtle.tokenId}`)} className='stakebutton w-1/2 m-2 xs:m-[0.4rem] 2xs:m-[0.35rem] 3xs:m-[0.3rem] flex justify-center text-center items-center xs:text-[0.85em] 2xs:text-[0.7em] 3xs:text-[0.65em] 4xs:text-[0.6em] rounded-md h-[25px] bg-green-300 hover:bg-[rgb(165,243,190)] border-b-[3px] border-green-400 active:border-b-[1px] active:translate-y-[2px]'>
                                                                <p className='staketext'>
                                                                    Stake
                                                                </p>
                                                                <img className='stakeimage ml-[3px] my-auto w-4 h-4' src={shelllogo} />
                                                            </button>
                                                            <button onClick={() => unstakeTurtle(`${turtle.tokenId}`)} className='unstakebutton w-1/2 m-2 xs:m-[0.4rem] 2xs:m-[0.35rem] 3xs:m-[0.3rem] flex justify-center text-center items-center xs:text-[0.85em] 2xs:text-[0.7em] 3xs:text-[0.65em] 4xs:text-[0.6em] rounded-md h-[25px] bg-red-300 hover:bg-[rgb(253,180,180)] border-b-[3px] border-red-400 active:border-b-[1px] active:translate-y-[2px]'>
                                                                Unstake
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )))}
                                    </div>
                                )}


                    </div>
                </div>
            </div>
            <footer className='mb-[20px] flex justify-center relative sm:bottom-1 md:bottom-3 lg:bottom-5 z-50 w-full h-[100px]'>
                <div className='mb-[20px] flex flex-row w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] h-full place-content-around'>
                    <a href='https://twitter.com/Arantuil' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={twitterlogo} alt="Twitter logo" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Twitter</h1>
                    </a>

                    <a href='https://energywebnfts.com/markets' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(54,151,44,0.9)] transition-all border-[rgba(44,124,37,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={marketicon} alt="NFT Marketplaces icon" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Markets</h1>
                    </a>

                    <a href='https://t.me/susu_market' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={telegramlogo} alt="Telegram logo" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Telegram</h1>
                    </a>
                </div>
            </footer>
        </div>
    );
}

export default MyTurtles;