import bgimg from '../assets/images/lake.png';
import MintpageTurtles from '../assets/images/mintpageturtles.png';
import ewtlogo from '../assets/images/EWTlogo.png';
import TurtleMetadata from '../Nftdata/TurtleMetadata';
import mintsuccessful from '../assets/sounds/mintsuccessful.mp3';
import mintinggif from '../assets/videos/generateegg.gif';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti'
import { useDispatch, useSelector } from 'react-redux';
import { connect } from '../redux/blockchain/blockchainActions';
import { fetchData } from '../redux/data/dataActions';

function Mint() {
    const [mintedSupply, setMintedSupply] = useState(0);
    async function getTotalSupply() {
        await fetch('https://explorer.energyweb.org/api?module=token&action=getToken&contractaddress=0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7')
            .then(response => response.json())
            .then(response => setMintedSupply(response["result"]["totalSupply"]))
    }
    useEffect(() => {
        getTotalSupply();
    }, [])

    const canvasStyles = {
        position: 'fixed',
        pointerEvents: 'none',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0
    }

    const refAnimationInstance = useRef(null);

    const getInstance = useCallback((instance) => {
        refAnimationInstance.current = instance;
    }, []);

    const makeShot = useCallback((particleRatio, opts) => {
        refAnimationInstance.current &&
            refAnimationInstance.current({
                ...opts,
                origin: { y: 0.7 },
                particleCount: Math.floor(200 * particleRatio)
            });
    }, []);

    const fire = useCallback(() => {
        makeShot(0.25, {
            spread: 40,
            startVelocity: 60
        });

        makeShot(0.2, {
            spread: 85
        });

        makeShot(0.35, {
            spread: 120,
            decay: 0.91,
            scalar: 0.8
        });

        makeShot(0.1, {
            spread: 150,
            startVelocity: 30,
            decay: 0.92,
            scalar: 1.2
        });

        makeShot(0.1, {
            spread: 150,
            startVelocity: 50
        });
    }, [makeShot]);

    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);
    const [claimingNft, setClaimingNft] = useState(false);
    const [feedback, setFeedback] = useState(`Click 'Mint' to mint your turtle.`);
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: ""
    });

    const [mintprice, setMintprice] = useState(3.5);

    useEffect(() => {
        if (data["totalSupply"] > 1500) {
            setMintprice(3.5)
        }
        if (data["totalSupply"] > 1600) {
            setMintprice(3.75)
        }
        if (data["totalSupply"] > 1700) {
            setMintprice(4)
        }
        if (data["totalSupply"] > 1800) {
            setMintprice(4.25)
        }
        if (data["totalSupply"] > 1900) {
            setMintprice(4.5)
        }
        if (data["totalSupply"] > 2000) {
            setMintprice(4.75)
        }
    }, [data])

    const claimNFTs = () => {
        let cost = parseInt((mintprice*1e18).toFixed(6))

        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost);
        let totalGasLimit = String(gasLimit);
        console.log('Cost: ', totalCostWei);
        console.log('Gas limit: ', totalGasLimit);
        setFeedback(`Minting your Tubby Turtle...`);
        document.getElementById('aoran').innerText = '';
        document.getElementById('myModalElement').innerText = '.......';
        document.getElementById("myModalElement").style.color = "#000032";

        setClaimingNft(true);
        blockchain.smartContract.methods
            .CreateTurtle(blockchain.account)
            .send({
                gasPrice: 100000000,
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once('error', (err) => {
                console.log(err);
                setFeedback('Sorry, something went wrong please try again later.');
                setClaimingNft(false);
                document.getElementById("myModal").style.display = "none";
            })
            .then((receipt) => {
                console.log(receipt)
                document.getElementById("myModal").style.display = "block";

                let mintedturtleid = (receipt.events.NewTurtle.returnValues.id);
                document.getElementById('myModalTurtleId').innerText = mintedturtleid

                let img = document.getElementById('myModalTurtleImg');
                img.src = mintinggif;

                let donatedamount = document.getElementById('donatedamount');
                donatedamount.innerText = (mintprice/10).toFixed(3);

                setTimeout(function () {
                    img = document.getElementById('myModalTurtleImg');
                    img.src = TurtleMetadata[mintedturtleid-1].image;

                    let elementtype = TurtleMetadata[mintedturtleid-1].attributes[1].value;
                    document.getElementById('myModalElement').innerText = elementtype;
                    let elementtypecolor;
                    let aoran;
                    if (elementtype === 'Fire') {
                        elementtypecolor = '#DF6B6A'
                        aoran = 'a '
                    }
                    if (elementtype === 'Water') {
                        elementtypecolor = '#7F91E6'
                        aoran = 'a '
                    }
                    if (elementtype === 'Poison') {
                        elementtypecolor = '#BB8FE6'
                        aoran = 'a '
                    }
                    if (elementtype === 'Electric') {
                        elementtypecolor = '#F4E07A'
                        aoran = 'an '
                    }
                    if (elementtype === 'Frost') {
                        elementtypecolor = '#B3E0D8'
                        aoran = 'a '
                    }
                    if (elementtype === 'Rock') {
                        elementtypecolor = '#F4AB67'
                        aoran = 'a '
                    }
                    if (elementtype === 'Nature') {
                        elementtypecolor = '#C2EA94'
                        aoran = 'a '
                    }
                    document.getElementById('aoran').innerText = aoran;
                    document.getElementById('myModalElement').style.color = elementtypecolor;
                    document.getElementById('mintsuccessful').play();
                    fire()
                }, 5000);
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
            });
    };

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    useEffect(() => {
        var modal = document.getElementById("myModal");

        var span = document.getElementsByClassName("close")[0];

        span.onclick = function () {
            modal.style.display = "none";
        }

        window.onclick = function (event) {
            if (event.target === modal) {
                modal.style.display = "none";
            }
        }
    });

    window.addEventListener('load', function () {
        startApp();
    })
    async function startApp() {
        window.ethereum.sendAsync({
            method: "eth_accounts",
            params: [],
            jsonrpc: "2.0",
            id: new Date().getTime()
        }, function (error, result) {
            if (result["result"] !== "") dispatch(connect());
        });
    }

    return (
        <div className='mt-24 sm:mt-28 md:mt-32 lg:mt-40 sm:mb-3 md:mb-12 flex justify-center items-center'>
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={bgimg} alt="Background image" />
            <div className='z-[1] min-h-[80vh] p-4 rounded-3xl w-[95%] sm:w-[80%] md:w-[75%] lg:w-[65%] xl:w-[55%] 2xl:w-[45%] bg-[rgba(158,211,148,0.9)]'>
                <div className='w-[85%] lg:w-[80%] xl:w-[70%] 2xl:w-[60%] max-w-[500px] flex justify-center text-center mx-auto translate-y-[1em]'>
                    <img src={MintpageTurtles} alt="Seven different Tubby Turtles" />
                </div>
                <h1 className='text-xl md:text-2xl font-bold flex text-center justify-center mt-6 px-4'>Get your own Tubby Turtle egg!</h1>
                <h2 className='leading-6 text-lg md:text-xl md:leading-7 flex text-center justify-center'>{mintedSupply} / {CONFIG.MAX_SUPPLY} have been minted</h2>
                
                <a className='underline leading-6 text-lg md:text-xl md:leading-7 flex text-center justify-center' href={CONFIG.SCAN_LINK} target={"_blank"} rel="noreferrer">
                    {String((CONFIG.CONTRACT_ADDRESS).slice(0, 8)) + '...' + String((CONFIG.CONTRACT_ADDRESS).slice(-8))}
                </a>
                
                {Number(mintedSupply) >= CONFIG.MAX_SUPPLY ? (
                    <>
                        <h2 className='leading-6 text-lg md:text-xl md:leading-7 flex text-center justify-center'>All turtles have been minted</h2>
                        <h2 className='leading-6 text-lg md:text-xl md:leading-7 flex text-center justify-center'>You can still find the Tubby Turtles collection on Greensea, Swappers and Raregems.</h2>
                    </>
                ) : (
                    <>
                        <div className='flex flex-row justify-center'>
                            <h2 className='leading-6 text-lg md:text-xl md:leading-7 flex text-center justify-center'>1 {CONFIG.SYMBOL} costs {mintprice} {" "}
                                {CONFIG.NETWORK.SYMBOL}</h2>
                            <img className='ml-1 translate-y-[3px] w-[22px] h-[22px]' src={ewtlogo} alt="EnergyWebToken logo" />
                        </div>
                        <h2 className='leading-6 text-lg md:text-xl md:leading-7 flex text-center justify-center'>The eggs will slowly hatch on their own in the following days after they are minted. <br />The color of the egg indicates which element type your turtle is going to be. <br />The mintingcost per egg will slowly increase as more get minted.</h2>
                        {0 > 1 ? (<div></div>)
                            : (
                                <>
                                    {blockchain.account === "" ||
                                        blockchain.smartContract === null ? (
                                        <div className='h-[100px] sm:h-[110px] md:h-[120px]'>
                                            <h2 className='text-base sm:text-lg md:text-xl flex text-center justify-center'>Connect to the {CONFIG.NETWORK.NAME} network</h2>
                                            <button className='mt-[5px] text-lg md:text-xl flex text-center pl-1 pr-2 mx-auto justify-center rounded-lg sm:rounded-xl items-center 
                                            bg-[#45e07e] border-[#28be5f] border-b-[3px] md:border-b-[5px] hover:brightness-[1.05] md:active:border-b-[3px] md:active:translate-y-[2px] active:border-b-[1px] active:translate-y-[2px]' onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch(connect());
                                                    getData();
                                                }}>

                                                <svg className='w-[35px] h-[35px] sm:w-[40px] sm:h-[40px] md:w-[45px] md:h-[45px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 318.6 318.6" strokeLinejoin="round">
                                                    <path d="M274.1 35.5l-99.5 73.9L193 65.8z" fill="#e2761b" stroke="#e2761b">
                                                    </path><g fill="#e4761b" stroke="#e4761b"><path d="M44.4 35.5l98.7 74.6-17.5-44.3zm193.9 171.3l-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="M33.9 207.7L50.1 263l56.7-15.6-26.5-40.6zm69.7-69.5l-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="M214.9 138.2l-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zm71.1-16.5l33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="M138.8 193.5l-28.2-8.3 19.9-9.1zm40.9 0l8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="M87.8 162.1l23.6 46-.8-22.9zm120.3 23.1l-1 22.9 23.7-46zm-64-20.6l-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0l-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="M179.8 193.5l-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3l.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="M177.9 230.9l-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                                </svg>
                                                Connect
                                            </button>

                                            {blockchain.errorMsg !== "" ? (
                                                <>
                                                    <h2 className='leading-6 text-lg md:text-xl md:leading-7 flex text-center justify-center'>{blockchain.errorMsg}</h2>
                                                </>
                                            ) : null}
                                        </div>

                                    ) : (
                                        <>
                                            <h2 className='leading-6 text-lg md:text-xl md:leading-7 flex text-center justify-center font-bold'>{feedback}</h2>

                                            <div className='pt-2 h-[65px]'>
                                                <button className='mt-[5px] text-xl md:text-2xl mx-auto rounded-lg sm:rounded-xl flex text-center px-6 py-[6px] justify-center
                                    bg-[#45e07e] border-[#28be5f] border-b-[3px] md:border-b-[5px] hover:brightness-[1.05] md:active:border-b-[3px] md:active:translate-y-[5px] active:border-b-[2px] active:translate-y-[4px] active:mt-[2px]'
                                                    disabled={claimingNft ? 1 : 0}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        claimNFTs();
                                                        getData();
                                                    }}>
                                                    {claimingNft ? "Pending" : "Mint"}
                                                </button>
                                                <audio id='mintsuccessful' src={mintsuccessful}></audio>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                    </>
                )}
                <div className='w-[90%] sm:w-[80%] mx-auto'>
                    <h3 className='pt-1 text-sm md:text-base flex text-center justify-center'>The mintingcost above might be slightly outdated, so please check the final mintingcost in your Metamask app.</h3>
                    <h3 className='text-sm md:text-base flex text-center justify-center'>Please make sure you are connected to the right network (
                        {CONFIG.NETWORK.NAME} Mainnet) and that you are connected with the correct address.</h3>
                </div>
            </div>

            <div id="myModal" className="z-[99] modal">
                <div id='myModalContent' className="modal-content rounded-3xl w-[90%] sm:w-[85%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%]">
                    <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
                    <span className="close">&times;</span>
                    <h2 style={{ display: 'hidden!important', fontFamily: 'Roboto', textAlign: 'center', textAlignLast: 'center' }}>Congratulations! Now you have your own Tubby Turtle egg!<br />
                        You minted #<span id='myModalTurtleId'></span> which is <span id='aoran'></span>
                        <span id='myModalElement'>.......</span> type!
                        <br />Watch it slowly hatch in the coming hours and days...👀
                    </h2>
                    <h2 className='text-center'>Thanks to you <span style={{ fontSize: 'calc(0.8em + 0.85vw)' }} id='donatedamount'>...</span><img className='ewtlogomint' src={ewtlogo} alt="" /> will be donated to the #Teamseas initiative!</h2>
                    <img id='myModalTurtleImg' className='flex mx-auto rounded-3xl max-h-[80vh] sm:max-h-[70vh] md:max-h-[60vh] lg:max-h-[55vh]' src="" alt="Your minted Tubby Turtle" />
                </div>
            </div>

        </div>
    );
}

export default Mint;