/* App.js */
import React from 'react';
import CanvasJSReact from '../../canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Background = () => {
		const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			title:{
				text: "Background"
			},
            axisX:{
                labelAngle: 130,
                interval: 1,
            },
			axisY: {
				includeZero: true
			},
			data: [{
				type: "column", //change type to bar, line, area, pie, etc
				//indexLabel: "{y}", //Shows y value on all Data Points
				indexLabelFontColor: "#5A5757",
				indexLabelPlacement: "outside",
				dataPoints: [
					{ label: "Blue", y: 9.62, color: "#D9D9D9" },
					{ label: "Cyan", y: 9.62, color: "#D9D9D9" },
					{ label: "Green", y: 9.62, color: "#D9D9D9" },
					{ label: "Orange", y: 9.62, color: "#D9D9D9" },
					{ label: "Yellow", y: 9.62, color: "#D9D9D9" },
					{ label: "Red", y: 9.62, color: "#D9D9D9" },
					{ label: "Pink", y: 6.86, color: "#70AC47" },
					{ label: "Purple", y: 6.86, color: "#70AC47" },
					{ label: "Underwater", y: 4.52, color: "#6F9AE7" },
					{ label: "Stars", y: 4.52, color: "#6F9AE7" },
					{ label: "Northpole", y: 4.52, color: "#6F9AE7" },
					{ label: "Island", y: 3.00, color: "#9B72EE" },
					{ label: "Valley", y: 3.00, color: "#9B72EE" },
					{ label: "Lake", y: 3.00, color: "#9B72EE" },
					{ label: "Moon", y: 2.00, color: "#EC9D33" },
					{ label: "Galaxy", y: 2.00, color: "#EC9D33" },
					{ label: "Night", y: 2.00, color: "#EC9D33" },
				]
			}]
		}
		
		return (
		<div>
			<div className='w-[80px] h-[24px] flex ml-0 bg-white z-[1000000] relative top-[11px]'>
			</div>
			<CanvasJSChart id='charts' options = {options} />
			<div className='w-[75px] h-5 bg-white relative bottom-3'>
			</div>
		</div>
		);
	}


export default Background