import Stars from '../assets/images/moon.png';
import Background from '../components/charts/Background';
import Element from '../components/charts/Element';
import Shell from '../components/charts/Shell';
import Shellbrim from '../components/charts/Shellbrim';
import Eyes from '../components/charts/Eyes';
import Hat from '../components/charts/Hat';
import Mouth from '../components/charts/Mouth';
import Artifact from '../components/charts/Artifact';
import ewtlogo from '../assets/images/EWTlogo.png';
import shellcoin from '../assets/images/shellcoin.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import { useEffect, useState } from 'react'
import { db, dbTwo } from '../firebase';
import { onValue, ref, get } from 'firebase/database';
import store from '../redux/store';
import { useQuery } from '@apollo/client';
import { GET_LPDATA } from '../queries/lpdata';
import teamseaslogosmall from '../assets/images/teamseaslogosmall.png'
import farm from '../assets/images/farmemoji.png';
import MetamaskAccountIcon from '../components/MetaMaskIcon';

const Statistics = () => {
    /* global BigInt */
    const dispatch = useDispatch();
    // blockchain contains the connection state of the blockchain
    const blockchain = useSelector((state) => state.blockchain);
    // data contains various variables such as: turtles a user owns/has staked, amount of approved SHL
    const bcdata = useSelector((state) => state.data);
    //console.log(bcdata)

    // Get and update the data variables on every change of connected address
    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };
    useEffect(() => {
        getData();
        if (blockchain.account !== "") {
            dispatch(fetchData());
        }
    }, [blockchain.account, dispatch]);
    // --------------------------------------

    // Get the config variables one time from the config.json file
    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        STAKINGCONTRACT_ADDRESS: "",
        FARMINGCONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: ""
    });
    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };
    useEffect(() => {
        getConfig();
    }, []);

    // --------------------------------------

    const [SHLPriceUSD, setSHLPriceUSD] = useState(0)
    const [avgTurtlePriceUSD, setAvgTurtlePriceUSD] = useState(0)

    async function getPriceDataDB() {
        let SHLUSDamount = await get(ref(dbTwo, '/' + 'SHLPriceUSD'));
        SHLUSDamount = SHLUSDamount["_node"]["value_"];
        SHLUSDamount = Number(SHLUSDamount);
        setSHLPriceUSD(SHLUSDamount);

        let avgMintPrice = await get(ref(dbTwo, '/' + 'avgMint'));
        avgMintPrice = avgMintPrice["_node"]["value_"];

        let ewtpriceusd = await get(ref(dbTwo, '/' + 'EWTPriceUSD'));
        ewtpriceusd = ewtpriceusd["_node"]["value_"];

        let avgTurtlePriceUSD = Number(avgMintPrice)*Number(ewtpriceusd)
        setAvgTurtlePriceUSD(avgTurtlePriceUSD);
    }

    useEffect(() => {
        getPriceDataDB();
    }, []);
    
    // ---------------------------------------------------

    const [shlBalanceStakingContract, setShlBalanceStakingContract] = useState(0);
    async function getShlBalanceStakingContract() {
        if (CONFIG.CONTRACT_ADDRESS !== "") {
            const response = await fetch(
                `https://explorer.energyweb.org/api?module=account&action=tokenbalance&contractaddress=${CONFIG.STAKINGCONTRACT_ADDRESS}&address=${CONFIG.STAKINGCONTRACT_ADDRESS}`
            ).then((response) => response.json());
            if (response["result"] !== null && response["result"] !== undefined) {
                setShlBalanceStakingContract((response["result"])/(1e+18));
            }
        }
    }

    const [shlBalanceNamingContract, setShlBalanceNamingContract] = useState(0);
    async function getShlBalanceNamingContract() {
        if (CONFIG.CONTRACT_ADDRESS !== "") {
            const response = await fetch(
                `https://explorer.energyweb.org/api?module=account&action=tokenbalance&contractaddress=${CONFIG.STAKINGCONTRACT_ADDRESS}&address=${CONFIG.CONTRACT_ADDRESS}`
            ).then((response) => response.json());
            if (response["result"] !== null && response["result"] !== undefined) {
                setShlBalanceNamingContract((response["result"])/(1e+18));
            }
        }
    }

    const [totalSupplyTurtles, setTotalSupplyTurtles] = useState(0);
    const [braveShieldError, setBraveShieldError] = useState(false);
    async function getTotalSupplyTurtles() {
        if (CONFIG.CONTRACT_ADDRESS !== "") {
            try {
                const response = await fetch(
                    `https://explorer.energyweb.org/api?module=stats&action=tokensupply&contractaddress=${CONFIG.CONTRACT_ADDRESS}`
                ).then((response) => response.json());
                if (response["result"] !== null && response["result"] !== undefined) {
                    setTotalSupplyTurtles((response["result"]));
                }
            } catch {setBraveShieldError(true)}
        }
    }

    const [totalStakedTurtles, setTotalStakedTurtles] = useState(0);
    async function getTotalStakedTurtles() {
        if (CONFIG.CONTRACT_ADDRESS !== "") {
            const response = await fetch(
                `https://explorer.energyweb.org/api?module=account&action=tokenbalance&contractaddress=${CONFIG.CONTRACT_ADDRESS}&address=${CONFIG.STAKINGCONTRACT_ADDRESS}`
            ).then((response) => response.json());
            if (response["result"] !== null && response["result"] !== undefined) {
                setTotalStakedTurtles((response["result"]));
            }
        }
    }

    useEffect(() => {
        getShlBalanceStakingContract();
        getShlBalanceNamingContract();
        getTotalSupplyTurtles();
        getTotalStakedTurtles();
    }, [CONFIG])

    // ---------------------------------------------------

    const [shlCirculatingSupply, setShlCirculatingSupply] = useState(0)
    const [shlTotalSupply, setShlTotalSupply] = useState(0)
    //const [averageTurtlePrice, setAverageTurtlePrice] = useState(0)
    //const [tradedTurtleAmount, setTradedTurtleAmount] = useState(0)
    const [avgMintPriceTurtle, setAvgMintPriceTurtle] = useState(0)
    const [ewtPriceUsd, setEwtPriceUsd] = useState(0)

    async function getSHLSupplyData() {
        let shlcirculating = await get(ref(dbTwo, '/' + 'SHLcirculatingSupply'));
        shlcirculating = shlcirculating["_node"]["value_"];
        shlcirculating = Number(shlcirculating);
        shlcirculating = shlcirculating.toFixed(0);
        setShlCirculatingSupply(shlcirculating);

        let shltotal = await get(ref(dbTwo, '/' + 'SHLtotalSupply'));
        shltotal = shltotal["_node"]["value_"];
        shltotal = Number(shltotal);
        setShlTotalSupply(shltotal);

        //let avgTurtlePrice = await get(ref(dbTwo, '/' + 'avgTurtlePrice'));
        //avgTurtlePrice = avgTurtlePrice["_node"]["value_"];
        //avgTurtlePrice = Number(avgTurtlePrice);
        //setAverageTurtlePrice(avgTurtlePrice);
        //
        //let tradedAmount = await get(ref(dbTwo, '/' + 'amountTradedTurtlesTotal'));
        //tradedAmount = tradedAmount["_node"]["value_"];
        //tradedAmount = tradedAmount - 1;
        //setTradedTurtleAmount(tradedAmount);

        let avgMintPrice = await get(ref(dbTwo, '/' + 'avgMint'));
        avgMintPrice = avgMintPrice["_node"]["value_"];
        setAvgMintPriceTurtle(avgMintPrice);

        let ewtpriceusd = await get(ref(dbTwo, '/' + 'EWTPriceUSD'));
        ewtpriceusd = ewtpriceusd["_node"]["value_"];
        setEwtPriceUsd(ewtpriceusd);
    }

    useEffect(() => {
        getSHLSupplyData();
    }, [])

    function numberWithCommas(x) {
        var parts = x.toString().split(",");
        parts[0]=parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,",");
        return parts.join(".");
    }

    const formatBigNumber = n => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
        if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
    };
    // -------------------------------------

    // Get donation counter data 
    let date_1 = new Date('01/01/2030');
    let date_2 = new Date();

    const days = (date_1, date_2) =>{
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays;
    }

    const [expectedSupplyIncreaseByStaking, setExpectedSupplyIncreaseByStaking] = useState(0);
    async function getDaysEachYearInAVariable() {
        let totalDaysUntilLastStakingDay = days(date_1, date_2);
        let daysInYears = [0,0,0,0,0,0,0];

        for (let index = 0; index < daysInYears.length; index++) {
            if (totalDaysUntilLastStakingDay > 365) {
                daysInYears[index] = 365;
                totalDaysUntilLastStakingDay -= 365;
            } 
            else {
                daysInYears[index] = totalDaysUntilLastStakingDay;
            }
        }

        let expectedIncreaseByStaking = 0;
        for (let loop = 0; loop < daysInYears.length; loop++) {
            if (daysInYears[loop] > 0) {
                if (loop === 0) { expectedIncreaseByStaking = expectedIncreaseByStaking + (totalStakedTurtles*400*365); }
                if (loop === 1) { expectedIncreaseByStaking = expectedIncreaseByStaking +  (totalStakedTurtles*200*365); }
                if (loop === 2) { expectedIncreaseByStaking = expectedIncreaseByStaking +  (totalStakedTurtles*100*365); }
                if (loop === 3) { expectedIncreaseByStaking = expectedIncreaseByStaking +  (totalStakedTurtles*50*365); }
                if (loop === 4) { expectedIncreaseByStaking = expectedIncreaseByStaking +  (totalStakedTurtles*25*365); }
                if (loop === 5) { expectedIncreaseByStaking = expectedIncreaseByStaking +  (totalStakedTurtles*12.5*365); }
                if (loop === 6) { expectedIncreaseByStaking = expectedIncreaseByStaking +  (totalStakedTurtles*6.25*365); }
            }
        }
        setExpectedSupplyIncreaseByStaking(expectedIncreaseByStaking);
    }

    useEffect(() => {
        getDaysEachYearInAVariable();
    }, [totalStakedTurtles])
    // --------------------------------------

    // Get donation counter data 
    function httpGet(theUrl) {
        let xmlHttpReq = new XMLHttpRequest();
        xmlHttpReq.open("GET", theUrl, false); 
        xmlHttpReq.send(null);
        return xmlHttpReq.responseText;
    }
    const hexToDecimal = hex => parseInt(hex, 16);

    const [contractWalletBalance, setContractWalletBalance] = useState(0)
    const [donationWalletBalance, setDonationWalletBalance] = useState(0)

    async function getContractWalletBalance() {
        let httpobj = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=energy-web-token&vs_currencies=usd')
        let ewtprice = await httpobj.json()
        ewtprice = ewtprice["energy-web-token"]["usd"]
        let httpobjbalance = httpGet('https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7')
        let tenprecent_balance_usd = ((hexToDecimal(JSON.parse(httpobjbalance)["result"]))/10000000000000000000)*ewtprice
        setContractWalletBalance(tenprecent_balance_usd)
    }

    async function getDonationWalletBalance() {
        let httpobj = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=energy-web-token&vs_currencies=usd')
        let ewtprice = await httpobj.json()
        ewtprice = ewtprice["energy-web-token"]["usd"]
        let httpobjbalance = httpGet('https://explorer.energyweb.org/api?module=account&action=eth_get_balance&address=0xCaFAdF88B3E6B9A9aDF4b04c23F442aA95452928')
        let tenprecent_balance_usd = ((hexToDecimal(JSON.parse(httpobjbalance)["result"]))/1000000000000000000)*ewtprice
        setDonationWalletBalance(tenprecent_balance_usd)
    }

    useEffect(() => {
        getContractWalletBalance();
        getDonationWalletBalance();
    }, [])


        // Calculate the value of the CLP token
        const [clpTokensInFarm, setClpTokensInFarm] = useState(0);
        const getClpTokensInFarm = async () => {
            try {
                const response = await fetch(
                    `https://explorer.energyweb.org/api?module=account&action=tokenbalance&contractaddress=0x013Dc10923Ce63381627Ce195F710Bc8cc81a8C9&address=0xd99A2f8CE0c503363f81484220113A9CAfE44DC3`
                ).then((response) => response.json());
                if (response["result"] !== null && response["result"] !== undefined) {
                    setClpTokensInFarm(response["result"]/1e+18);
                }
            } catch {setBraveShieldError(true)}
        };
    
        const [clpTokensTotalSupply, setClpTokensTotalSupply] = useState(0);
        const getTokensTotalSupply = async () => {
            try {
                const response = await fetch(
                    `https://explorer.energyweb.org/api?module=stats&action=tokensupply&contractaddress=0x013Dc10923Ce63381627Ce195F710Bc8cc81a8C9`
                ).then((response) => response.json());
                if (response["result"] !== null && response["result"] !== undefined) {
                    setClpTokensTotalSupply(response["result"]/1e+18);
                }
            } catch {setBraveShieldError(true)}
        };
        
        const [reserveUSD, setReserveUSD] = useState(0);
        var { loading, error, data } = useQuery(GET_LPDATA);
        useEffect(() => {
            if (data !== undefined && data !== null) {
                setReserveUSD(parseFloat(data["liquidityPositionSnapshots"][0]["reserveUSD"]).toFixed(3));
            }
        }, [loading]);
    
        const [LPvaluePerToken, setLPvaluePerToken] = useState(0);
        useEffect(() => {
            getClpTokensInFarm();
            getTokensTotalSupply();
            if (parseFloat(reserveUSD) >= 0 && parseFloat(clpTokensTotalSupply) >= 0) {
                setLPvaluePerToken((parseFloat(reserveUSD)/parseFloat(clpTokensTotalSupply)));
            }
        }, [reserveUSD, clpTokensTotalSupply]);
        // --------------------------------------------------

        // Get SHL price
        const [shlPrice, setShlPrice] = useState(0);
        useEffect(() => {
            if (data !== undefined) {
                setShlPrice(parseFloat(data["token"]["derivedETH"])*parseFloat(data["pair"]["token1Price"]))
            }
        }, [loading, data]);
    
        // Calculate APY
        const [apyPercentage, setApyPercentage] = useState(0);
        function calculateLP_APY() {
            if (clpTokensInFarm > 0) { 
                let percentageUserOfPool = parseFloat((1)/(clpTokensInFarm));
                let userEarningsPerSecond = 0.786*percentageUserOfPool;
                let userEarningsPerYear = userEarningsPerSecond*60*60*24*365;
                
                let userEarningsPerYearInUSD = userEarningsPerYear*shlPrice
                let userInvestmentInUSD = LPvaluePerToken*1
                let calculatedAPY = userEarningsPerYearInUSD/userInvestmentInUSD*100;
                setApyPercentage(calculatedAPY.toFixed(2));
            }
        }
    
        useEffect(() => {
            calculateLP_APY();
        }, [shlPrice, LPvaluePerToken, clpTokensInFarm]);

    const [daysUntilHalving, setDaysUntilHalving] = useState(0);
    function shlHalvingCountdown() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const nextNewYear = new Date(currentYear + 1, 0, 1);
        
        const timeDifference = nextNewYear - currentDate;
        const daysRemaining = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        
        setDaysUntilHalving(daysRemaining);
    }

    useEffect(() => {
        shlHalvingCountdown()
    }, []);

    const [tokenHolders, setTokenHolders] = useState([]);
    const apiUrl = 'https://explorer.energyweb.org/api?module=token&action=getTokenHolders&contractaddress=0x013Dc10923Ce63381627Ce195F710Bc8cc81a8C9';

    useEffect(() => {
        const getTokenHoldersData = async () => {
            try {
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setTokenHolders(data.result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        getTokenHoldersData();
    }, []);

    console.log(tokenHolders)


    return (
        <div className='statspage sm:mb-2 md:mb-8 lg:mb-14 w-full h-full flex justify-center'>
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={Stars} alt="Background" />
            <div className='text-xl h-full w-[94%] md:w-[85%] lg:w-[80%] xl:w-[70%] 2xl:w-[65%] p-4 sm:p-5 md:p-6 lg:p-7 mt-24 sm:mt-28 md:mt-32 lg:mt-40 bg-white rounded-3xl'>

                    <div>
                            {braveShieldError === false ? ( 
                                    <></>
                                ) : (
                                    <div className='text-[rgba(230,110,110)] mb-2 px-4 sm:px-8 md:px-16 text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Your shield is blocking the requests to gather some of the statistics. You can take your Brave browser shield down🙂. There are no ads here.</div>
                                )}
                            <div className='rounded-xl bg-[rgba(165,102,255,0.12)] p-2 sm:p-3 md:p-4'>
                                <h1 className='mb-1 sm:mb-2 text-center font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl'><img className='w-6 -translate-y-[3px] sm:w-7 md:w-8 inline rounded-full aspect-square mr-1 sm:mr-2' src="/android-chrome-512x512.png" alt="" />Tubby Turtles statistics:</h1>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Circulating turtle supply: <span
                                        className='font-bold'>{totalSupplyTurtles}
                                    </span>
                                    </p>
                                </div>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Max turtle supply: <span
                                        className='font-bold'>{2100}
                                    </span>
                                    </p>
                                </div>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Turtles on a sea adventure: <span
                                        className='font-bold'>{totalStakedTurtles}
                                    </span>
                                    </p>
                                </div>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Percentage of turtles on a sea adventure: <span
                                        className='font-bold'>{((totalStakedTurtles/totalSupplyTurtles)*100).toFixed(2)}%
                                    </span>
                                    </p>
                                </div>
                                <div className='my-3 sm:my-5'>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Average turtle mintprice: <span className='font-bold'>{avgMintPriceTurtle.toFixed(2)}</span></p>
                                        <img className='inline h-4 sm:h-5 ml-1 my-auto aspect-square' src={ewtlogo} alt="" />
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Current turtle mintprice: <span className='font-bold'>{3}</span></p>
                                        <img className='inline h-4 sm:h-5 ml-1 my-auto aspect-square' src={ewtlogo} alt="" />
                                    </div>  
                                    {/*
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Average secondary market turtle price: <span className='font-bold'>{averageTurtlePrice.toFixed(2)}</span></p>
                                        <img className='inline h-4 sm:h-5 ml-1 my-auto aspect-square' src={ewtlogo} alt="" />
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Turtles traded on the secondary market: <span className='font-bold'>{tradedTurtleAmount}</span></p>
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Total turtle trade volume: <span className='font-bold'>{(averageTurtlePrice*tradedTurtleAmount).toFixed(2)}</span></p>
                                        <img className='inline h-4 sm:h-5 ml-1 my-auto aspect-square' src={ewtlogo} alt="" />
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Total turtle trade volume in USD (based on current EWT price): <span className='font-bold'>${(averageTurtlePrice*tradedTurtleAmount*ewtPriceUsd).toFixed(2)}</span></p>
                                    </div>
                                    */}
                                </div>
                                <div className='flex'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Average Tubby Turtle sea adventure APY based on average mintprice: <span
                                        className='font-bold'>{(((400 * SHLPriceUSD) / (avgTurtlePriceUSD * 1)) * 100 * 365).toFixed(2)}%
                                    </span>
                                    </p>
                                </div>
                            </div>

                            <div className='mt-4 sm:mt-5 md:mt-6 rounded-xl bg-[rgba(94,198,250,0.12)] p-2 sm:p-3 md:p-4'>
                                <h1 className='mb-1 sm:mb-2 text-center font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl'><img className='w-6 -translate-y-[3px] sm:w-7 md:w-8 inline rounded-full aspect-square mr-1 sm:mr-2' src={shellcoin} alt="" />SeaShell token statistics:</h1>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Circulating SHL supply: <span
                                        className='font-bold'>{numberWithCommas(shlCirculatingSupply)}
                                    </span> / <span className='font-bold'>{formatBigNumber(parseInt(shlCirculatingSupply))}</span> / <span className='font-bold'>{((shlCirculatingSupply/(parseInt(shlCirculatingSupply)+expectedSupplyIncreaseByStaking))*100).toFixed(3)}% of expected total supply</span>
                                    </p>
                                </div>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Total SHL supply: <span
                                        className='font-bold'>{numberWithCommas(Math.round(shlTotalSupply))}
                                    </span> / <span className='font-bold'>{formatBigNumber(parseInt(shlTotalSupply))}</span>
                                    </p>
                                </div>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Expected final total supply: <span
                                        className='font-bold'>{numberWithCommas(parseInt(shlCirculatingSupply)+expectedSupplyIncreaseByStaking)}
                                    </span> / <span className='font-bold'>{formatBigNumber(parseInt(shlCirculatingSupply)+expectedSupplyIncreaseByStaking)}</span>
                                    </p>
                                </div>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Maximum SHL supply: <span
                                        className='font-bold'>{numberWithCommas(685000000)}
                                    </span> / <span className='font-bold'>{formatBigNumber(parseInt(685000000))}</span>
                                    </p>
                                </div>
                                <div className='flex flex-row'>
                                    <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl mx-auto'>Halving countdown: <span
                                        className='font-bold'>{parseInt(daysUntilHalving)} Days
                                    </span>
                                    </p>
                                </div>
                                <div className='mt-3 sm:mt-5'>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Current SHL price: <span className='font-bold'>${SHLPriceUSD.toFixed(8)}</span></p>
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>SHL marketcap: <span className='font-bold'>${numberWithCommas(Math.round(SHLPriceUSD*shlCirculatingSupply))}</span></p>
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>SHL fully diluted marketcap (expected total supply): <span className='font-bold'>${numberWithCommas(Math.round(SHLPriceUSD*(parseInt(shlCirculatingSupply)+expectedSupplyIncreaseByStaking)))}</span></p>
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>SHL fully diluted marketcap (max. supply): <span className='font-bold'>${numberWithCommas(Math.round(SHLPriceUSD*685000000))}</span></p>
                                    </div>
                                </div>
                                <div className='my-3 sm:my-5'>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>SHL "burned" by staking: <span className='font-bold'>{numberWithCommas(shlBalanceStakingContract)}</span></p>
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>SHL "burned" by name changes: <span className='font-bold'>{numberWithCommas(Math.round(shlBalanceNamingContract))}</span></p>
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Total SHL "burned": <span className='font-bold'>{numberWithCommas(shlBalanceStakingContract+shlBalanceNamingContract)}</span></p>
                                    </div>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Percentage SHL circulating supply reduced by "burns": <span className='font-bold'>{((1-((parseInt(shlCirculatingSupply)+parseInt(shlBalanceStakingContract)+parseInt(shlBalanceNamingContract))/parseInt(shlCirculatingSupply)))*100).toFixed(2)}%</span></p>
                                    </div>
                                </div>

                        <div>
                            <h1 className='text-center font-bold s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Top 10 SHL liquidity providers:</h1>
                            <ul className='text-center 2xs:text-[0.65rem] xs:text-[0.68rem] s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>
                                {tokenHolders.slice(0,10).map((holder, index) => (
                                    <li className='flex flex-col border-[2px] border-blue-200 my-[4px] rounded-xl' key={index}>
                                        {holder.address === '0x6ef3d22d4fc7a48a76477e088430e6a720f7a22e' ? (
                                            <abbr className='text-purple-800 hover:text-purple-500 mx-auto' title={'Tubby Turtles Liquidity address'}>
                                                <p className='flex flex-row mx-auto gap-2 truncate'><span className='translate-y-1'><MetamaskAccountIcon address={holder.address} size={22} /></span> {holder.address} </p>
                                            </abbr>
                                        ) : (
                                            holder.address === '0xd99a2f8ce0c503363f81484220113a9cafe44dc3' ? (
                                                <abbr className='text-purple-800 hover:text-purple-500 mx-auto' title={'SHLLiquidityFarmV1'}>
                                                    <p className='flex flex-row mx-auto gap-2 truncate'><span className='translate-y-1'><MetamaskAccountIcon className='translate-y-1' address={holder.address} size={22} /></span> {holder.address} </p>
                                                </abbr>
                                            ) : (
                                                holder.address === '0x063d155c0f8b1e5bf5c0c501b89dd23e7527ddc9' ? (
                                                    <abbr className='text-purple-800 hover:text-purple-500 mx-auto' title={'SHLLiquidityFarmV2'}>
                                                        <p className='flex flex-row mx-auto gap-2 truncate'><span className='translate-y-1'><MetamaskAccountIcon className='translate-y-1' address={holder.address} size={22} /></span> {holder.address} </p>
                                                    </abbr>
                                                ) : (
                                                <p className='flex flex-row mx-auto gap-2 truncate'><span className='translate-y-1'><MetamaskAccountIcon className='translate-y-1' address={holder.address} size={22} /></span> {holder.address} </p>
                                                )
                                            )
                                        )}
                                        <p className='flex flex-row mx-auto font-bold'><span className='mr-1 font-normal'>Value:</span> {parseInt(BigInt(holder.value)/BigInt(1e18))} CLP </p>
                                    </li>
                                ))}
                            </ul>
                        </div>

                                <div className='mt-3 sm:mt-5 bg-[rgba(255,155,41,0.2)] p-2 rounded-xl'>
                                    <div className='flex flex-row justify-center'>
                                        <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>Link to the farm (V2):</p>
                                    </div>
                                    <div className='my-1 h-[84px]'>
                                        <div className='rounded-md pt-1 md:py-2 my-auto border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2 mx-auto w-1/5 bg-[rgba(255,155,41,1)] transition-all border-[rgba(194,118,31,1)]'>
                                            <a className='' href="https://farm.tubbyturtles.com/" target="_blank" rel="noreferrer">
                                                <img className='mx-auto w-[28px] h-[28px]' src={farm} alt="" />
                                                <h1 className='font-bold flex justify-center text-2xl'>Farm</h1>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-row justify-center px-4 sm:px-8 md:px-16 lg:px-32'>
                                    <p className='text-center s:text-[0.6rem] s:leading-[0.75rem] text-[0.8rem] leading-[1.00rem] sm:text-xs md:text-sm'>"burned" in the above mentioned cases mean they are in a smart contract (with a withdraw function) and they are essentially burned, until decided otherwise by the SHL governance</p>
                                </div>
                                
                            </div>

                            <div className='mt-4 sm:mt-5 md:mt-6 p-2 sm:p-3 md:p-4 rounded-xl bg-[rgba(0,255,0,0.09)] flex justify-center flex-col mx-auto'>
                                <h1 className='font-bold text-center text-lg sm:text-xl md:text-2xl lg:text-3xl'>
                                <img className='w-6 -translate-y-[3px] sm:w-7 md:w-8 inline rounded-full aspect-square mr-1 sm:mr-2' src={teamseaslogosmall} alt="" />Donation counter
                                </h1>
                                <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>
                                    Total current donated amount to #Teamseas, raised by all Tubby Turtle holders and traders: <span className='font-bold counter text-lg sm:text-xl md:text-2xl lg:text-3xl' style={{ color: '#10A32D' }}>$680</span>
                                </p>
                                <p className='text-center s:text-xs text-sm sm:text-base md:text-lg lg:text-xl'>
                                    Donation backlog: <span className='font-bold' style={{ color: '#B7A416' }}>${(contractWalletBalance+donationWalletBalance).toFixed(2)}</span>
                                </p>
                            </div>

                    </div>
                <div className='p-2 sm:p-4 md:p-6 lg:p-9 xl:p-12 2xl:p-16'>
                    <h1 className='my-2 pb-2 pt-5 border-b-[2px] md:border-b-[3px] border-black rounded-lg text-center font-bold text-lg sm:text-xl md:text-2xl lg:text-3xl'>The projected chances for all the Tubby Turtle traits</h1>
                    <div className='w-full'><Background /></div>
                    <div className='w-full'><Element /></div>
                    <div className='w-full'><Shell /></div>
                    <div className='w-full'><Shellbrim /></div>
                    <div className='w-full'><Eyes /></div>
                    <div className='w-full'><Hat /></div>
                    No hat: 36.62%
                    <div className='w-full'><Mouth /></div>
                    <div className='w-full'><Artifact /></div>
                    No artifact: 79.76%
                </div>
            </div>
        </div>
    );
}

export default Statistics;