/* App.js */
import React from 'react';
import CanvasJSReact from '../../canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Hat = () => {
		const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			title:{
				text: "Hat"
			},
            axisX:{
                labelAngle: 130,
                interval: 1,
            },
			axisY: {
				includeZero: true
			},
			data: [{
				type: "column", //change type to bar, line, area, pie, etc
				//indexLabel: "{y}", //Shows y value on all Data Points
				indexLabelFontColor: "#5A5757",
				indexLabelPlacement: "outside",
				dataPoints: [
					{ label: "Red beanie", y: 4.43, color: "#70AC47" },
					{ label: "Light gray beanie", y: 4.43, color: "#70AC47" },
					{ label: "Black beanie", y: 3.48, color: "#6F9AE7" },
					{ label: "Ricehat", y: 3.48, color: "#6F9AE7" },
					{ label: "Halo", y: 3.48, color: "#6F9AE7" },
					{ label: "Bear hat", y: 3.48, color: "#6F9AE7" },
                    { label: "Polarbear hat", y: 3.48, color: "#6F9AE7" },
					{ label: "Headphones", y: 3.48, color: "#6F9AE7" },
					{ label: "Flower", y: 3.48, color: "#6F9AE7" },

					{ label: "Wizard hat", y: 2.14, color: "#9B72EE" },
					{ label: "Dark wizard hat", y: 2.14, color: "#9B72EE" },
					{ label: "Crown", y: 2.14, color: "#9B72EE" },
					{ label: "Frog hat", y: 2.14, color: "#9B72EE" },
					{ label: "Glass bowl helmet", y: 2.14, color: "#9B72EE" },
					{ label: "Gangster hat", y: 2.14, color: "#9B72EE" },
					{ label: "White cat ears", y: 2.14, color: "#9B72EE" },
					{ label: "Black cat ears", y: 2.14, color: "#9B72EE" },
					{ label: "Dog ears", y: 2.14, color: "#9B72EE" },
					{ label: "Devil horns", y: 2.14, color: "#9B72EE" },

					{ label: "Green partyhat", y: 1.10, color: "#EC9D33" },
                    { label: "Purple partyhat", y: 1.10, color: "#EC9D33" },
                    { label: "Red partyhat", y: 1.10, color: "#EC9D33" },
                    { label: "White partyhat", y: 1.10, color: "#EC9D33" },
                    { label: "Yellow partyhat", y: 1.10, color: "#EC9D33" },
                    { label: "Blue partyhat", y: 1.10, color: "#EC9D33" },
					{ label: "Bitcoin face tattoo", y: 1.10, color: "#EC9D33" },
                    { label: "Laurel leaves", y: 1.10, color: "#EC9D33" },
				]
			}]
		}
		
		return (
		<div>
			<div className='w-[80px] h-[24px] flex ml-0 bg-white z-[1000000] relative top-[11px]'>
			</div>
			<CanvasJSChart id='charts' options = {options} />
			<div className='w-[75px] h-5 bg-white relative bottom-3'>
			</div>
		</div>
		);
	}


export default Hat