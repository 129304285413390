// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();
      let turtleCost = await store
        .getState()
        .blockchain.smartContract.methods.turtleCost()
        .call();
      let account = await store.getState()["blockchain"]["account"];
      let turtleOwner = await store
        .getState()
        .blockchain.smartContract.methods.walletOfOwner(account)
        .call();
      let approveAllowance = await store
        .getState()
        .blockchain.stakingSmartContract.methods.allowance(account, "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7")
        .call();
      let approveContractAllowance = await store
        .getState()
        .blockchain.stakingSmartContract.methods.allowance(account, "0xc6c6239614723298591f16bB2f779C9199b5AB1a")
        .call();
      let totalstakedamount = await store
        .getState()
        .blockchain.smartContract.methods.balanceOf("0xc6c6239614723298591f16bB2f779C9199b5AB1a")
        .call();
      let turtlesStaked = await store
        .getState()
        .blockchain.stakingSmartContract.methods.getTokensStaked(account)
        .call();
      let earnedShells = await store
        .getState()
        .blockchain.stakingSmartContract.methods.getAllRewards(account)
        .call();
      //let turtleInformation = []
      //for (let i = 0; i < Number(totalSupply)+1; i++) {
      //  let turtleInfo = await store
      //    .getState()
      //    .blockchain.smartContract.methods.turtles(i)
      //    .call();
      //    for (let i2 = 0; i2 < turtleOwner.length; i2++) {
      //      if (turtleInfo[0] === turtleOwner[i2]) {
      //        turtleInformation.push(turtleInfo)
      //      }
      //    }
      //}
      let clpTotalSupply = await store
      .getState()
      .blockchain.farmingSmartContract.methods.totalSupply()
      .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          turtleCost,
          turtleOwner,
          approveAllowance,
          approveContractAllowance,
          totalstakedamount,
          turtlesStaked,
          earnedShells,
          //turtleInformation,
          clpTotalSupply,
        })
      );
    } catch (err) {
      //console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
