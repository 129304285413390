/* App.js */
import React from 'react';
import CanvasJSReact from '../../canvasjs.react';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Artifact = () => {
		const options = {
			animationEnabled: true,
			exportEnabled: true,
			theme: "light2", //"light1", "dark1", "dark2"
			title:{
				text: "Artifact"
			},
            axisX:{
                labelAngle: 130,
                interval: 1,
            },
			axisY: {
				includeZero: true
			},
			data: [{
				type: "column", //change type to bar, line, area, pie, etc
				//indexLabel: "{y}", //Shows y value on all Data Points
				indexLabelFontColor: "#5A5757",
				indexLabelPlacement: "outside",
				dataPoints: [
					{ label: "Green konpeito", y: 2.67, color: "#70AC47" },
					{ label: "Pink konpeito", y: 2.67, color: "#70AC47" },
					{ label: "Yellow konpeito", y: 2.67, color: "#70AC47" },
					{ label: "Energy web", y: 2.14, color: "#6F9AE7" },
					{ label: "Bitcoin", y: 1.43, color: "#9B72EE" },
					{ label: "Ethereum", y: 1.43, color: "#9B72EE" },
					{ label: "Susu", y: 0.90, color: "#EC9D33" },
                    { label: "EnergyWeb DOGE", y: 0.90, color: "#EC9D33" },
					{ label: "Ocean protocol", y: 0.90, color: "#EC9D33" },
					{ label: "Polkadot", y: 0.90, color: "#EC9D33" },
					{ label: "Cryptosoots", y: 0.90, color: "#EC9D33" },
					{ label: "Smudge", y: 0.90, color: "#EC9D33" },
                    { label: "Poison bottle", y: 0.90, color: "#EC9D33" },
					{ label: "SeaShell", y: 0.90, color: "#EC9D33" },
				]
			}]
		}
		
		return (
		<div>
			<div className='w-[80px] h-[24px] flex ml-0 bg-white z-[1000000] relative top-[11px]'>
			</div>
			<CanvasJSChart id='charts' options = {options} />
			<div className='w-[75px] h-5 bg-white relative bottom-3'>
			</div>
		</div>
		);
	}


export default Artifact