import homebgvideo from '../assets/videos/homebgvid.mkv'
import distributionchart from '../assets/images/distributionchart.png'
import teamseaslogosmall from '../assets/images/teamseaslogosmall.png'
import ShellAllocation from '../assets/images/ShellAllocation.png'
import gifdifferentturtles from '../assets/videos/gif_different_turtles.gif'
import blueblob from '../assets/images/blueblob.png'
import { Link } from 'react-router-dom'
import Elements from '../components/ElementsSVG'
import RoyaltiesSchematic from '../components/RoyaltiesSchematic'
import twitterlogo from '../assets/images/twitterlogo.png'
import marketicon from '../assets/images/marketicon.png'
import telegramlogo from '../assets/images/telegramlogo.png'
import ewtshell from '../assets/images/shellcoin.png'
import MintingSchedule from '../components/MintingSchedule'
import turtlestake from '../assets/images/turtlestake.png'
import turtlenamechange from '../assets/images/turtlenamechange.png'
import txTrackerLogo512 from '../assets/images/txTrackerLogo512.png'
import auctionlogo from '../assets/images/auctionlogo.png'
import { AiOutlineLink } from 'react-icons/ai'
import eyes from '../assets/images/eyesemoji.png'
import vote from '../assets/images/lightningemoji.png'

const Home = () => {
    return (
        <div className=''>
            <section className='relative w-[calc(100vw-17px)] overflow-hidden sm:overflow-visible h-full'>

                <div className='z-[20] w-[130%] sm:w-full md:w-[85%] lg:w-[75%] xl:w-[65%] 2xl:w-[50vw] lg:-translate-y-10 -translate-x-16 sm:-translate-x-0 h-1/3 my-auto mx-auto absolute top-0 bottom-0 left-0 right-0'>
                    <div className='overflow-hidden sm:overflow-visible relative ml-0 left-0 -translate-x-[10px]'>
                        <img className='overflow-hidden w-[calc(100%)] ml-6 h-auto' src={blueblob} alt="blueBlob" />

                        <div className='z-[20] overflow-hidden flex flex-row ml-2 w-3/4 h-3/4 absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                            <div className='w-1/2 h-full'>
                                <img className='ml-6 h-full rounded-3xl' src={gifdifferentturtles} alt="gifTurtlesPreview" />
                            </div>
                            <div className='w-[45%] h-full flex flex-col'>
                                <div className='mb-1 -translate-x-[7px] sm:-translate-x-[0px] sm:mb-0 w-full h-1/2 sm:h-[60%]'>
                                    <h1 className='text-white ml-[9vw] sm:ml-[6vw] md:ml-[5vw] lg:ml-[5vw] xl:ml-[4vw] 2xl:ml-[2.5vw] font-bold text-[calc(0.35rem+5vw)] md:text-[calc(0.35rem+4.2vw)] lg:text-[calc(0.30rem+3.5vw)] xl:text-[calc(0.24rem+3.3vw)] 2xl:text-[calc(0.17rem+2.9vw)] text-bottom'>Tubby<br/>Turtles</h1>
                                </div>
                                <div className='mt-2 relative top-0 w-[80%] flex justify-center h-1/3'>
                                    <div className='rounded-xl w-full h-full bg-[#22C55E] transition-all border-[#188841] border-b-[3px] sm:border-b-[5px] active:border-b-[2px] active:translate-y-1'>
                                        <Link className='text-[calc(0.21rem+2.2vw)] md:text-[calc(0.15rem+2.0vw)] lg:text-[calc(0.17rem+1.6vw)] xl:text-[calc(0.15rem+1.2vw)] rounded-xl w-full h-full flex justify-center items-center my-auto bg-green-500 text-white font-bold' to='/mint'>
                                            Mint your own turtle!
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <video src={homebgvideo} className='opacity-[0.85] w-[100vw] h-[100vh] object-cover' loop autoPlay muted></video>
            </section>
            <svg className='absolute w-full h-[75px] md:h-[100px] bottom-0 overflow-hidden translate-y-[60px]' preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1000 80">
                <path fill="#eddd81" strokeWidth="0.767" d="M0 1.807l100.193 7.711 100-1.586 100-7.932 100 9.518 100 5.288 100-8.46 100-1.058 100 7.05 100.272-9.101L1000 5.157V80H0z"></path><path fill="#dbd876" strokeWidth="0.767" d="M0 24.29l100 5.816 100-4.76 100-3.172 100 1.058 100 8.989 100-7.403 100 5.817 100-5.288 100-3.702 100 5.288V80H0z"></path><path fill="#c7d36c" strokeWidth="0.767" d="M0 32.41l100 5.287 100.272-4.662L300 39.813l100-3.702 100 1.586 100 6.346 99.728-5.432L800 32.938l100 2.644 100-3.172V80H0z"></path><path fill="#b2cf64" strokeWidth="0.767" d="M0 48.353l101.156-3.306 98.651 2.788 100.964 1.103 99.229.287 100-6.24 99.23 9.08L700 45.63l98.91 8.124L900 45.74l100 1.763V80H0z"></path><path fill="#9bca5e" strokeWidth="0.767" d="M0 64.873l100-8.46 100 7.403 100-6.345 100-1.587 100 3.702 100 .528 100-4.759 100 9.518 100 1.058 100-4.759V80H0z"></path>
            </svg>
            <div>
                <section className='pb-3 sm:py-6 md:py-10 flex justify-center bg-[#9BCA5E]'>
                    <div className='-translate-y-[12px] pt-14 w-[85%] md:w-[70%] lg:w-[60%] xl:w-1/2'>
                        <h1 className='relative z-30 font-bold text-2xl sm:text-3xl md:text-4xl pb-2'>What is Tubby Turtles?</h1>

                        <p className='font-semibold text-base sm:text-lg md:text-xl'>Tubby Turtles is a stakable NFT project where every minted turtle is unique.
                            The home of the turtles is the Energy Web Chain,
                            where they live in the form of ERC-721 tokens.
                        </p>
                        <br />
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>Each turtle has different traits, including element type, shell color, eye types, hats, and a small chance on a rare artifact.
                            There are 120+ different possible traits, with 5 different rarity types: 
                            <span style={{ color: '#D9D9D9' }}>{/* className='has-tooltip' */}
                                <span className='tooltip rounded-xl border-2 border-[#97b66f] shadow-xl p-1 bg-[#b5d689] text-black -mt-10'>42,76% of all traits have the rarity: <span style={{ color: '#D9D9D9' }}> Common</span>
                                </span> Common</span>,
                            <span style={{ color: '#70AC47' }}>{/* className='has-tooltip' */}
                                <span className='tooltip rounded-xl border-2 border-[#97b66f] shadow-xl p-1 bg-[#b5d689] text-black -mt-10'>35,36% of all traits have the rarity: <span style={{ color: '#70AC47' }}> Uncommon</span>
                                </span> Uncommon</span>, 
                            <span style={{ color: '#6B9AE7' }}>{/* className='has-tooltip' */}
                                <span className='tooltip rounded-xl border-2 border-[#97b66f] shadow-xl p-1 bg-[#b5d689] text-black -mt-10'>23,51% of all traits have the rarity: <span style={{ color: '#6B9AE7' }}> Rare</span>
                                </span> Rare</span>,
                            <span style={{ color: '#9B72EE' }}>{/* className='has-tooltip' */}
                                <span className='tooltip rounded-xl border-2 border-[#97b66f] shadow-xl p-1 bg-[#b5d689] text-black -mt-10'>9,84% of all traits have the rarity: <span style={{ color: '#9B72EE' }}> Epic</span>
                                </span> Epic</span>, and, 
                            <span style={{ color: '#EC9D33' }}>{/* className='has-tooltip' */}
                                <span className='tooltip rounded-xl border-2 border-[#97b66f] shadow-xl p-1 bg-[#b5d689] text-black -mt-10'>Only 2,72% of all traits have the rarity: <span style={{ color: '#EC9D33' }}> Legendary</span>
                                </span> Legendary</span> 
                            .
                        </p>
                        <br />
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>All the <span>current</span> Energy Web related projects are included as a possible 'legendary' artifact.
                            Every Tubby Turtle has one of the following 7 base element types; <a className='hover:brightness-[130%]' href="/elements/fire"><span style={{ color: '#E54E5A' }}>Fire</span></a>, <a className='hover:brightness-[135%]' href="/elements/water"><span style={{ color: '#496BFF' }}>Water</span></a>, <a className='hover:brightness-[125%]' href="/elements/poison"><span style={{ color: '#A566FF' }}>Poison</span></a>, <a className='hover:brightness-[120%]' href="/elements/electric"><span style={{ color: '#FFEB59' }}>Electric</span></a>, <a className='hover:brightness-[115%]' href="/elements/frost"><span style={{ color: '#8FECEF' }}>Frost</span></a>, <a className='hover:brightness-[120%]' href="/elements/rock"><span style={{ color: '#FF993F' }}>Rock</span></a> and <a className='hover:brightness-[125%]' href="/elements/nature"><span style={{ color: '#9EFF75' }}>Nature</span></a>. Combining the power of all the turtle element types in your sea adventures will grant your turtles special powers*.
                        </p>
                        <p className='font-semibold text-xs sm:text-sm md:text-base'>
                            * not yet implemented
                        </p>
                        <br />
                        <div className='flex mx-auto w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%]'>
                            <Elements />
                        </div>
                    </div>
                </section>
                <svg className='w-full h-[75px] md:h-[100px] z-50 overflow-hidden' preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1000 80">
                    <path fill="#94c3ee" strokeWidth="0.787" d="M0 80h1000V0H0z"></path><path fill="#56ccea" strokeWidth="0.787" d="M0 58.578l124.4-8.335L250 56.242l126.326 4.906L500 64.038l125.556-8.353 123.288 8.626 125.941-7.73L1000 62.368V0H0z"></path><path fill="#26d2cc" strokeWidth="0.787" d="M0 49.003l125.556-8.353 125.065 7.135 124.935-7.692 124.527 5.36 125.858-.67 124.252 8.03 125.363-4.367L1000 43.403V0H0z"></path><path fill="#5bd298" strokeWidth="0.787" d="M0 29.238l125.556 4.455L250 28.603l125.556-1.874L500 37.309l125.556-2.228L750 41.764l125.556 1.67L1000 33.064V3.34H0z"></path><path fill="#9bca5e" strokeWidth="0.787" d="M0 17.774l126.519-4.347L250 17.774l125.556-1.67 124.308 7.026 125.555-1.565 124.445 7.795 125.555-4.454L1000 21.22V0H0z"></path>
                </svg>
                <section className='py-2 sm:py-6 md:py-10 flex justify-center bg-[#94C3EE]'>
                    <div className='w-[85%] md:w-[70%] lg:w-[60%] xl:w-1/2'>
                        <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl pb-2'>NFT Tokenomics</h1>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>The maximum amount of turtles that is ever able to be minted is 2100.</p>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>40 Tubby Turtles will be pre-minted to then be used in various giveaways, competitions and treasure hunts...</p>
                        <img className='mx-auto w-[90%] sm:w-4/5 md:w-3/5 lg:w-1/2 mb-4 mt-1' src={distributionchart} alt="" />
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>Minting a turtle costs only 1.5 EWT at launch, and will slightly increase as more turtles get minted.</p>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>10% of the minting costs will be donated to #teamseas <img className='w-[24px] h-[24px] inline' src={teamseaslogosmall} alt="" /> (teamseas.org) in 4 parts. The first donation after 525/2100 minted, and the following 3 parts at: 1050, 1575, 2100.</p>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>Another 10% (now 20%) will be used as the initial liquidity for the Tubby Turtles token: SeaShell (SHL).</p>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>The remaining 80% (now 70%) will go the developer of the project.</p>
                        <div className='py-2 w-full'>
                            <MintingSchedule />
                            <p className='font-semibold text-xs sm:text-sm md:text-base'>Minting price schedule (price top-left, and the amount of turtles for that particular price at the bottom)</p>
                        </div>
                        <p className='mt-5 font-semibold text-base sm:text-lg md:text-xl'>There is a 4% royalty fee on every Tubby Turtle traded on the secondary market:
                            <li>
                                2.5% will be used for liquidity for the SeaShell token. Half of the EWT amount will be converted to SHL and then added to the EWT/SHL liquidity pool on Carbonswap.
                            </li>
                            <li>
                                0.5% goes to the founder/dev wallet.
                            </li>
                            <li>
                                1% will get donated to #teamseas <img className='w-[24px] h-[24px] inline' src={teamseaslogosmall} alt="" /> (teamseas.org).
                                All the EWT will be periodically sent to an exchange to be sold for a coin that teamseas accepts as payment,
                                and then the final donated amount after fees will be shared in the Telegram group.
                            </li>
                        </p>
                        <h2 className='font-semibold text-xs sm:text-sm md:text-base mb-2'>
                            *Note that the final amount will be less than 1% of the volume that month, because of the swap/exchange fees and withdrawal fees.
                        </h2>
                        <div>
                            <div className='font-semibold w-full mx-auto'>
                                <RoyaltiesSchematic />
                                <p className='mt-1 font-semibold text-xs sm:text-sm'>(Clicking on any of the highlighted contracts/wallets will open up an energyweb explorer link)
                                </p>
                            </div>
                        </div>
                        <div className='mt-5'>
                            <h3 className='font-semibold text-base sm:text-lg md:text-xl'>
                                Holding a Tubby Turtle will grant extra features on:<a className='text-blue-500 hover:text-purple-500 flex flex-row underline hover:brightness-125' target="_blank" rel="noreferrer" href="https://txtracker.energywebnfts.com"><img className='w-7 h-7 rounded-lg mr-2' src={txTrackerLogo512} />txtracker.energywebnfts.com<AiOutlineLink /></a>
                            </h3>
                            <ul className='ml-7 font-semibold text-base sm:text-lg md:text-xl'>
                                <li>
                                    - The ability to query the EWT in- and outflow data from and to the exchanges of any timeframe you want. 
                                </li>
                            </ul>
                            <h3 className='font-semibold text-base sm:text-lg md:text-xl'>
                                <a className='flex flex-row underline hover:brightness-125 text-blue-500 hover:text-purple-500' target="_blank" rel="noreferrer" href="https://auction.energywebnfts.com"><img className='w-7 h-7 rounded-lg mr-2' src={auctionlogo} />auction.energywebnfts.com<AiOutlineLink /></a>
                            </h3>
                            <ul className='ml-7 font-semibold text-base sm:text-lg md:text-xl'>
                                <li>
                                    - 10% sellers fee rebate, or 25% if you hold a turtle with the corresponding coin artifact to the token that the auction uses. (If you're holding a turtle with an EWT coin artifact then opening an auction and selecting EWT as the sale token will give you 25% of the sale fee back.)
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <svg className='w-full h-[75px] md:h-[100px] z-50 overflow-hidden' preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1000 70" >
                    <path fill="#eddd81" strokeWidth="0.623" d="M0 80h1000V0H0z"></path> <path fill="#a9e39e" strokeWidth="0.623" d="M0 53.45l100 2.445 100 12.577 100 1.397 99.23-9.7 100-2.795L600 67.424l100-4.193 100-6.637 99.422 7.173L1000 59.04V0H0z" ></path> <path fill="#70e0ca" strokeWidth="0.623" d="M0 43.668l98.652 3.115L200 40.524l100 11.405 100-1.49 100.77-8.168 98.46 6.2 100.385.072 100.93-8.907L901.09 51.778l98.91-2.52V0H0z" ></path> <path fill="#69d4ea" strokeWidth="0.623" d="M0 27.948l100 7.685 101.348-14.845L300 39.127l101.156-7.702L500 31.092l99.23-1.9L700 37.38l102.697-7.696 99.615 7.588L1000 28.297V0H0z" ></path> <path fill="#94c3ee" strokeWidth="0.623" d="M0 12.602l100.385 9.936L200 13.275l99.23 3.683L400 23.755l100-8.034 100 4.89 100 2.795 100-3.843 100-.349 100 2.096V0H0z" ></path>
                </svg>
                <section className='py-4 sm:py-8 md:py-12 flex justify-center bg-[#EDDD81]'>
                    <div className='w-[85%] md:w-[70%] lg:w-[60%] xl:w-1/2'>
                        <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl pb-2'>
                            Sea adventure
                        </h1>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>
                            In the 'My turtles' tab on the 'The sea' page you can send your Tubby Turtles into the sea on an adventure. When you call them back, they may be returning bearing some treasure they have found in the sea.
                        </p>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>
                        Combining the power of all the seven turtle element types (Fire, Water, Poison, Electric, Frost, Rock and Nature) in your sea adventures will grant your turtles special powers*.
                        </p>
                        <p className='font-semibold text-xs sm:text-sm md:text-base'>
                            * not yet implemented
                        </p>
                        <div className='flex relative w-[100px] mt-2 mb-[4.5em] md:mb-[4.25em] lg:mb-[4em] lg:mt-3'>
                            <Link className='rounded-md absolute px-4 bg-[rgba(255,220,43,0.9)] transition-all border-[rgba(201,173,34,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' to='/sea/my'>
                                <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]' src={eyes} alt="" />
                                <h1 className='font-bold flex justify-center text-lg sm:text-xl md:text-2xl lg:text-3xl whitespace-nowrap'>The sea</h1>
                            </Link>
                        </div>
                    </div>
                </section>
                <svg className='w-full h-[75px] md:h-[100px] z-50 overflow-hidden' xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 1000 80"
                    ><path fill="#ddeef2" fillOpacity="1" d="M0 80h1000V0H0z"></path><path fill="#c1f0eb" fillOpacity="1" d="M0 62.062l111.09 3.265 111.324-4.325 110.92 3.33 111.11 2.27 111.415-10.14 110.807 10.141 111.112-7.947 111.11 8.174L1000 59.316V0H0z"></path><path fill="#b6f0d0" fillOpacity="1" d="M0 47.521l111.54-4.85 111.11 3.84 110.683 11.01 111.111-12.488 111.112 1.513 112.018 11.732 110.204-7.191 110.918.696L1000 44.367V0H0z"></path><path fill="#c7eaa7" fillOpacity="1" d="M0 29.895l111.325-4.051 109.368 12.095 112.853-3.26 110.898 2.195 111.415-9.476 110.807 13.017 111.112-7.113 110.726 4.444L1000 29.799V0H0z"></path><path fill="#eddd81" d="M0 10.4l111.111 3.964 110.897 9.537 111.325-6.931 110.203 5.961 112.02-7.794 111.11 3.027 109.598 4.541 112.624-9.081L1000 19.072V0H0z"></path>
                </svg>
                <section className='py-2 sm:py-6 md:py-10 flex justify-center bg-[#DDEEF2]'>
                    <div className='w-[85%] md:w-[70%] lg:w-[60%] xl:w-1/2'>
                        <h1 className='translate-y-1 font-bold text-2xl sm:text-3xl md:text-4xl pb-2'>
                            SeaShell token (SHL)
                            <span><img className='ml-2 mb-1 inline-block w-10 h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14' src={ewtshell} alt="shellTokenLogo" /></span>
                        </h1>
                        <div className='flex flex-col lg:flex-row'>
                            <p className='font-semibold text-base sm:text-lg md:text-xl'>
                            The Tubby Turtles staking reward and governance token is: SeaShell (SHL)
                            </p>
                        </div>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>
                            The base staking rewards are 10 SHL/day. (The base staking rewards gets halved every year for the next 7 years, after that the base reward for staking will be 0.)
                            Every turtle has a rarity score which can vary from 16-100, where a score of 100 is the maximum rarity a Tubby Turtle can have.
                            This rarity score will act as a multiplier for the staking rewards a staked turtle earns.
                            
                            <br/>
                            (For example, a turtle with a rarity score of 48 will get 48*10 = 480 SHL/day, and with the lowest possible rarity score being 16, this would mean that any turtle will earn atleast 16*10 = 160 SHL/day)
                        </p>
                        <br/>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>
                            The SeaShell token will be used in all Tubby Turtles related features/additions:
                        </p>
                        <ul className='font-semibold text-lg sm:text-xl md:text-2xl'>
                            <li className='flex flex-row items-center'>
                                <img className='my-2 h-[100px] w-[100px] mr-2' src={turtlenamechange} alt="" />
                                Changing the name of your turtle costs 7500 SeaShells.
                            </li>
                            <li className='flex flex-row items-center'>
                                <img className='my-2 h-[100px] w-[100px] mr-2' src={turtlestake} alt="" />
                                Staking a turtle costs 5.000 SeaShells (to disincentivize short term staking/unstaking).
                            </li>
                        </ul>

                        <p className='font-semibold text-base sm:text-lg md:text-xl'>
                            The SHL token is also the governance token for the Tubby Turtles and SeaShell project.
                            <br/>
                            Link to the governance page can be found below and in the header of the site.
                            Anyone with atleast 10.000 SHL is able to make a proposal about any suggestions/changes they would like to happen.
                            These ideas, suggestions, and/or changes can be either about Tubby Turtles, SHL or both.
                        </p>

                        <div className='flex relative h-[60px] w-[100px] mt-2 mb-[4.5em] md:mb-[4.25em] lg:mb-[4em] lg:mt-3'>
                            <a className='rounded-md pt-[4px] w-[160px] absolute px-4 bg-[rgba(177,81,255,0.9)] transition-all border-[rgba(138,64,199,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2' href='https://vote.tubbyturtles.com/' rel='noreferrer'>
                                <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px]' src={vote} alt="" />
                                <h1 className='font-bold flex justify-center text-lg sm:text-xl md:text-2xl lg:text-3xl whitespace-nowrap'>Vote</h1>
                            </a>
                        </div>

                        <h1 className='translate-y-1 font-bold text-2xl sm:text-3xl md:text-4xl mb-4'>
                            SHL token liquidity
                        </h1>
                        <p className='font-semibold text-base sm:text-lg md:text-xl'>
                            Initial liquidity:
                            <br/>
                            - 10% of all minting costs will be used to provide the initial starting liquidity for the EWT/SHL pair.
                            <br/>
                            <br/>
                            Ongoing liquidity:
                            <br/>
                            - 2.5% of the tubby turtle secondary market sales is regularly added to the liquidity for the SeaShell token. Half of the EWT amount gets converted to SHL and then added to the EWT/SHL liquidity pool on Carbonswap.
                            <br/>
                            - All earnings from <a className='text-blue-500 hover:text-purple-500' href="https://auction.energywebnfts.com/" target="_blank" rel="noreferrer">EWCNFTAuctionHouse</a> will be added to the EWT/SHL liquidity pool.
                            <br/>
                            - All earnings from <a className='text-blue-500 hover:text-purple-500' href="https://place.energywebnfts.com/" target="_blank" rel="noreferrer">EW/Place</a> will be added to the EWT/SHL liquidity pool.
                            <br/>
                        </p>
                        <p className='line-through font-semibold text-base sm:text-lg md:text-xl'>
                            - The <a className='line-through text-blue-500 hover:text-purple-500' href="https://farm.tubbyturtles.com/" target="_blank" rel="noreferrer">SHL Liquidity Farm</a> for the pair EWT/SHL with over 8.1 Million SHL being distributed in 120 days to increase the liquidity for SHL.
                        </p>

                        <h2 className='mt-6 sm:mt-8 md:mt-10 font-bold text-2xl sm:text-3xl md:text-4xl pb-2'>SeaShell token allocation</h2>
                        <img className='rounded-lg sm:rounded-2xl' src={ShellAllocation} alt="" />
                    </div>
                </section>
                <svg className='w-full h-[75px] md:h-[100px] z-50 overflow-hidden' xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 1000 80"
                    ><path fill="#9ee2b1" fillOpacity="1" d="M0 80h1000V0H0z"></path><path fill="#a0e7cc" fillOpacity="1" d="M0 62.062l111.09-4 111.93 10.81 110.92-9.08 110.504 6.81 111.415-7.72 110.807 7.721 111.112-7.947 111.11 8.174L1000 59.316V0H0z"></path><path fill="#aeeae1" fillOpacity="1" d="M0 47.521l112.144 3.324 110.809-1.912 109.775-2.915 111.413 5.069 111.415-4.541 108.991 5.678 115.047-8.704 110.01.696L1000 49.513V0H0z"></path><path fill="#c4ecee" fillOpacity="1" d="M0 29.895l111.325-4.051 109.67 8.765 112.55.07 110.899 2.195 111.112-6.146 108.688-.461 113.534 3.035 110.726 4.444L1000 29.799V0H0z"></path><path fill="#ddeef2" fillOpacity="1" d="M0 10.4l111.111 6.991 111.502-2.874 110.72 7.296 111.111-6.146 111.112-.53 110.327 5.959 110.079-6.867 112.623 4.54L1000 15.138V0H0z"></path>
                </svg>
                <section className='flex flex-col py-2 sm:py-6 md:py-10 justify-center bg-[#9EE2B1]'>
                    <footer className='bg-[#9EE2B1] flex justify-center relative -bottom-4 sm:-bottom-5 md:-bottom-6 z-50 w-full h-[100px]'>
                        <div className='flex flex-row w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] h-full place-content-around'>
                            <a href='https://twitter.com/TurtlesTubby' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                                <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={twitterlogo} alt="" />
                                <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Twitter</h1>
                            </a>
                            <a href='https://energywebnfts.com/nft/Tubby_Turtles' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(54,151,44,0.9)] transition-all border-[rgba(44,124,37,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                                <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={marketicon} alt="" />
                                <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Markets</h1>
                            </a>
                            <a href='https://t.me/tubbyturtles' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                                <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={telegramlogo} alt="" />
                                <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Telegram</h1>
                            </a>
                        </div>
                    </footer>
                </section>
            </div>
        </div>
    );
}

export default Home;