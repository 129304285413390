import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages/Home';
import Mint from './pages/Mint';
import Stats from './pages/Stats';
import Header from './components/Header';
import AllElements from './pages/AllElements';
import Elements from './pages/Elements';
import TurtleRenderer from "./pages/TurtleRenderer";
import AllTurtles from "./pages/AllTurtles";
import MyTurtles from "./pages/MyTurtles";
import Transfer from './pages/TurtleTransfer';

import store from './redux/store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Router>
      <Provider store={store}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/sea/all" element={<AllTurtles />} />
          <Route path="/sea/my" element={<MyTurtles />} />
          <Route path="/stats" element={<Stats />} />
          <Route exact path="/elements" element={<AllElements />} />
          <Route path="/elements/*" element={<Elements />} />
          <Route path="/*" element={<TurtleRenderer />} />
        </Routes>
      </Provider>
    </Router>
  );
}

// firebase deploy --only hosting:devenv
// "target": "devenv",

export default App;