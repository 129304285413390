import bgimg from '../assets/images/mintbg.png';
import { connect } from '../redux/blockchain/blockchainActions';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import twitterlogo from '../assets/images/twitterlogo.png';
import marketicon from '../assets/images/marketicon.png';
import telegramlogo from '../assets/images/telegramlogo.png';
import shelllogo from '../assets/images/shellcoin.png';
import turtleNameChange from '../assets/images/turtlenamechange2.png'
import PulseLoader from 'react-spinners/PulseLoader';
import { db, dbTwo } from '../firebase';
import { onValue, ref, get } from 'firebase/database';
import TurtleMetadata from '../Nftdata/TurtleMetadata';
import Fire from '../assets/images/elementsymbols/Fire.png';
import Water from '../assets/images/elementsymbols/Water.png';
import Poison from '../assets/images/elementsymbols/Poison.png';
import Electric from '../assets/images/elementsymbols/Electric.png';
import Frost from '../assets/images/elementsymbols/Frost.png';
import Rock from '../assets/images/elementsymbols/Rock.png';
import Nature from '../assets/images/elementsymbols/Nature.png';
import AllElements from '../assets/images/elementsymbols/AllElements.png';
import { AiOutlineReload, AiOutlineSearch } from 'react-icons/ai';
import useScript from '../components/hooks/useScript';
import ShlbalanceCount from '../components/ShlBalanceCount';
import auctionlogo from '../assets/images/auctionlogo.png';
import raregemslogo from '../assets/images/raregemslogo.png';
import EWTlogo from '../assets/images/EWTlogo.png';

function AllTurtles() {
    const [loading, setLoading] = useState(false);
    const [mintedamount, setMintedamount] = useState(0);
    const [braveShieldError, setBraveShieldError] = useState(false);
    async function getTotalSupplyTurtles() {
        try {
            const response = await fetch(
                `https://explorer.energyweb.org/api?module=stats&action=tokensupply&contractaddress=0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7`
            ).then((response) => response.json());
            if (response["result"] !== null && response["result"] !== undefined) {
                setMintedamount((response["result"]));
            }
        } catch {setBraveShieldError(true)}
    }

    useEffect(() => {
        getTotalSupplyTurtles();
    }, [])

    function getElementTurtleIcon(input) {
        if (input === 'Fire') { return Fire }
        if (input === 'Water') { return Water }
        if (input === 'Poison') { return Poison }
        if (input === 'Electric') { return Electric }
        if (input === 'Frost') { return Frost }
        if (input === 'Rock') { return Rock }
        if (input === 'Nature') { return Nature }
    }

    const [Turtles, setTurtles] = useState(TurtleMetadata)
    const [Turtlesbase, setTurtlesbase] = useState(TurtleMetadata)
    useEffect(() => {
        onValue(ref(db), snapshot => {
            const firebasedata = snapshot.val();
            setTurtles(firebasedata);
        })
    }, []);

    async function updateFirebaseTurtles() {
        onValue(ref(db), snapshot => {
            const firebasedata = snapshot.val();
            setTurtlesbase(firebasedata);
        })
    }

    function getAllFireElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Fire' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllWaterElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Water' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllPoisonElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Poison' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllElectricElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Electric' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllFrostElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Frost' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllRockElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Rock' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllNatureElem() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] === 'Nature' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllTurtles() { updateFirebaseTurtles(); const result = Turtlesbase.filter(turtle => turtle.attributes[1]["value"] !== '' && turtle.tokenId < mintedamount); setTurtles(result) }
    function getAllNamedTurtles() { 
        updateFirebaseTurtles(); 
        const result = Turtlesbase.filter(turtle => !(turtle.name).includes('Tubby Turtle')); 
        setTurtles(result) 
    }

    const [loadedTurtles, setLoadedTurtles] = useState(25)
    const [amountToLoadMore, setAmountToLoadMore] = useState(42)
    function loadMoreTurtles() {
        let amount = loadedTurtles;
        amount += amountToLoadMore;
        if (amount > mintedamount) {
            amount = mintedamount
            amount = parseInt(amount)+1
        }
        if (amount > 2100) {
            amount = 2100
        }
        setLoadedTurtles(amount)
    }

    function searchturtle() {
        let search = document.getElementById('turtlesearchtxt').value;
        let totalsup = parseInt(mintedamount)
        if (parseInt(search) < totalsup+1) {
            const result = Turtlesbase.filter(turtle => 
            parseInt(turtle.tokenId) === parseInt(search));
            setTurtles(result);
            let texthtml = document.getElementById('responsetext')
            texthtml.innerText = ''
        }
        else if (search === "") {
            const result = Turtlesbase.filter(turtle => 
                parseInt(turtle.tokenId) < totalsup+1);
                setTurtles(result);
        }
        else if (search !== "") {
            const result = Turtlesbase.filter(turtle => 
                ((turtle.name).toLowerCase()) === String(search).toLowerCase());
                setTurtles(result);
        }
        else {
            let responsetext = 'To search for a specific turtle you have to put in a valid ID of a turtle that has already been minted.'
            let texthtml = document.getElementById('responsetext')
            texthtml.innerText = responsetext
        }
    }

    useEffect(() => {
        var turtlesearchbox = document.getElementById("turtlesearchtxt");
        if (turtlesearchbox !== null) {
            turtlesearchbox.addEventListener("keypress", function(event) {
                if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("turtlesearchbutton").click();
                }
            });
        }
    }, [])

    const [tokens_count, setTokens_count] = useState(0);
    const [owners_count, setOwners_count] = useState(0);
    const [offers_count, setOffers_count] = useState(0);
    const [floor_price, setFloor_price] = useState(0);
    async function getRaregemswidget() {
        const r = await (await fetch(`https://raregems.io/api/collection_widget?chain=${'energyweb'}&collection=${'tubby-turtles'}`)).json()
        
        setTokens_count(r.tokens_count);
        setOwners_count(r.owners_count);
        setOffers_count(r.offers_count);
        setFloor_price(r.floor_price);
        //console.log(r.tokens_count, r.owners_count, r.offers_count, r.floor_price)
    };

    useEffect(() => {
        getRaregemswidget();
    }, [])

    const [removeImages, setRemoveImages] = useState(false)

    window.onscroll = function() {
        let allturtlesbody = document.getElementsByClassName('allturtlesbody')
        allturtlesbody = allturtlesbody[0]
        try {
            if ((window.innerHeight + Math.ceil(window.pageYOffset)) >= allturtlesbody.offsetHeight) {
                loadMoreTurtles();
                //colorRarity();
            }
        } catch {console.log('Uncaught TypeError: Cannot read properties of undefined (reading offsetHeight)')}
    }

    async function toggleQuickScroll() {
        setAmountToLoadMore(100);
        if (removeImages === false) {
            setRemoveImages(true);
        } else {
            setRemoveImages(false);
        }
    }

    return (
        <div className="flex flex-col mx-auto w-[96%] md:w-[88%] lg:w-[83%] xl:w-[76%] h-[calc(100vh-100px)] mt-[95px] sm:mt-[110px] md:mt-[135px] lg:mt-[160px] mb-6 sm:mb-12 md:mb-20 lg:mb-28">
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={bgimg} alt="Background image" />

            <div className='rounded-2xl flex flex-col bg-[rgb(231,241,255)] mb-4 sm:mb-7 md:mb-10 lg:mb-12 xl:mb-14'>
                <div className='rounded-2xl w-full h-[75px] xs:h-[65px] 2xs:h-[55px] 3xs:h-[50px] 4xs:h-[45px] flex flex-row'>
                    <Link to='/sea/all' className='rounded-tl-2xl bg-[rgb(231,241,255)] font-bold w-1/2 h-full justify-center flex items-center text-2xl s:text-xl xs:text-lg'>
                        All turtles
                    </Link>
                    <Link to='/sea/my' className='rounded-tr-2xl bg-[rgb(201,226,255)] font-bold w-1/2 h-full justify-center flex items-center text-2xl s:text-xl xs:text-lg'>
                        My turtles
                    </Link>
                </div>
                <div>
                        <div className='w-full z-[0] allturtlesbody' id='viewerbodyusersturtles'>
                        <div className='flex flex-col md:w-[90%] lg:w-[80%] mx-auto'>

                            {/*
                            <div className='mt-3 md:mt-6'>
                                <ShlbalanceCount />
                            </div>
                            */}
                            
                            <div className='flex justify-center py-3 md:py-6'>
                                <button className='text-sm sm:text-base md:text-lg bg-blue-300 hover:brightness-110 rounded-md w-[200px] sm:w-[225px] md:w-[250px] px-[8px] md:px-[12px] py-[5px] md:py-[6px]'><a href='/transfer'>Send a turtle to a new home</a></button>
                            </div>
                            <div className='mx-auto'>
                                <h2 className='text-lg s:text-base xs:text-sm text-center'>
                                    You can trade/auction turtles on:
                                </h2>
                                <div className='p-1 sm:p-2 my-2 sm:my-3 rounded-lg bg-green-100'>
                                    <p className='text-xl s:text-lg xs:text-base 2xs:text-sm my-1 text-center'><span><img className='mr-1 w-7 s:w-6 xs:w-5 rounded-lg inline' src={auctionlogo} /></span><span className='text-blue-500 hover:text-purple-700'><a href='https://auction.energywebnfts.com/' target='_blank' rel='noreferrer'>EWCNFTAuctionHouse</a></span>
                                    </p>

                                    <p className='text-lg s:text-base xs:text-sm 2xs:text-xs'>(10-25% fee cashback for Tubby Turtle holders)</p>
                                </div>
                                <div className='p-1 sm:p-2 my-2 sm:my-3 rounded-lg bg-purple-100'>
                                    <p className='text-xl s:text-lg xs:text-base 2xs:text-sm  my-1 text-center'><span><img className='mr-1 w-7 s:w-6 xs:w-5 rounded-lg inline' src={raregemslogo} /></span><span className='text-blue-500 hover:text-purple-700'><a href='https://raregems.io/' target='_blank' rel='noreferrer'>Raregems</a></span>
                                    </p>

                                    <div className='text-lg s:text-base xs:text-sm 2xs:text-xs flex flex-row justify-between'>
                                        <div className='flex flex-col text-center'>
                                            <p>Supply</p>
                                            <p>{tokens_count}</p>
                                        </div>
                                        <div className='flex flex-col text-center'>
                                            <p>Owners</p>
                                            <p>{owners_count}</p>
                                        </div>
                                        <div className='flex flex-col text-center'>
                                            <p>Offers</p>
                                            <p>{offers_count}</p>
                                        </div>
                                        <div className='flex flex-col text-center'>
                                            <p>Floorprice</p>
                                            <p>{floor_price}<span><img className='inline w-4 ml-[3px] -translate-y-[2px]' src={EWTlogo} /></span></p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='flex flex-row justify-center mb-3 md:mb-6 mt-3 md:mt-6 px-1 sm:px-3 mx-4 sm:mx-6 md:mx-8 lg:mx-10 xl:mx-12 py-3 md:py-4 rounded-xl bg-white'>
                                <button id='buttonNames' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllNamedTurtles}>
                                    <img className='rounded-full' src={turtleNameChange} alt="" />
                                </button>
                                <button id='button1' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllTurtles}>
                                    <img className='rounded-full' src={AllElements} alt="" />
                                </button>
                                <button id='button2' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllFireElem}>
                                    <img src={Fire} alt="" />
                                </button>
                                <button id='button3' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllWaterElem}>
                                    <img src={Water} alt="" />
                                </button>
                                <button id='button4' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllPoisonElem}>
                                    <img src={Poison} alt="" />
                                </button>
                                <button id='button5' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllElectricElem}>
                                    <img src={Electric} alt="" />
                                </button>
                                <button id='button6' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllFrostElem}>
                                    <img src={Frost} alt="" />
                                </button>
                                <button id='button7' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllRockElem}>
                                    <img src={Rock} alt="" />
                                </button>
                                <button id='button8' className='hover:brightness-110 w-10 h-10 sm:w-11 sm:h-11 md:w-12 md:h-12 lg:w-14 lg:h-14 mx-[2px] sm:mx-2 md:mx-3 rounded-full border-[3px] sm:border-[5px] active:border-[2px] sm:active:border-[3px] border-white' onClick={getAllNatureElem}>
                                    <img src={Nature} alt="" />
                                </button>
                            </div>
                            <div className='flex flex-col mx-auto mb-3 md:mb-6'>
                                <div className='flex justify-center'>
                                    <input className='text-sm md:text-base lg:text-lg border-[#CED4DA] hover:border-[#228BE6] border-2 rounded-md px-[5px] py-[3px]' id='turtlesearchtxt' type="text" />
                                    <button onClick={searchturtle} className='bg-blue-300 hover:brightness-110 rounded-md px-2 ml-1 sm:ml-3 flex flex-row items-center' id='turtlesearchbutton'><AiOutlineSearch className='my-auto' /><span className='s:hidden'>Search</span></button>
                                    <button onClick={toggleQuickScroll} className='s:text-sm xs:text-xs bg-red-300 hover:brightness-110 rounded-md px-2 ml-1 sm:ml-3 flex flex-row items-center'>Quick scroll</button>
                                </div>
                                <div className='flex flex-row justify-center'>
                                    <p id='responsetext'></p>
                                </div>
                            </div>
                        </div>
                        <div className='nftcontainer flex flex-wrap justify-center rounded-xl p-4 bg-[rgb(208,230,255)]'>
                            
                            {(Turtles.slice(0, loadedTurtles).map(turtle => (
                                <div className='nftcard hover:scale-[1.08] transition-all w-[200px] bg-white rounded-xl m-4'>
                                    <Link to={`/${turtle.tokenId}`}>
                                        <img className='elementicon absolute w-[3.75em] -translate-x-4 -translate-y-5 rounded-full' src={getElementTurtleIcon(turtle.attributes[1]["value"])} alt="" />
                                    </Link>
                                    {removeImages === false ? (
                                    <Link to={`/${turtle.tokenId}`}>
                                        <img className='nftimg w-[calc(100%-14px)] h-auto rounded-xl flex mx-auto m-[7px]' src={turtle.image} alt="" />
                                    </Link>
                                    ) : (<></>)}
                                    <div className='flex flex-col mb-2'>
                                        <div className='nftinfocardtext'>
                                            {(turtle.name).includes('egg') === false ? (
                                                turtle.attributes[8]["value"] > 15 && turtle.attributes[8]["value"] <= 25 ? (
                                                    <h2 className='text-gray-600 flex items-center turtlerarity bg-[rgba(235,235,235)] px-[6px] rounded-full font-bold absolute overflow-hidden text-center text-base xs:text-sm 2xs:text-xs translate-x-3'>{turtle.attributes[8]["value"]}</h2>
                                                ) : (
                                                    (turtle.name).includes('egg') === false ? (
                                                        turtle.attributes[8]["value"] > 25 && turtle.attributes[8]["value"] <= 40 ? (
                                                            <h2 className='text-green-600 flex items-center turtlerarity bg-[rgba(235,235,235)] px-[6px] rounded-full font-bold absolute overflow-hidden text-center text-base xs:text-sm 2xs:text-xs translate-x-3'>{turtle.attributes[8]["value"]}</h2>
                                                        ) : (
                                                            (turtle.name).includes('egg') === false ? (
                                                                turtle.attributes[8]["value"] > 40 && turtle.attributes[8]["value"] <= 55 ? (
                                                                    <h2 className='text-blue-600 flex items-center turtlerarity bg-[rgba(235,235,235)] px-[6px] rounded-full font-bold absolute overflow-hidden text-center text-base xs:text-sm 2xs:text-xs translate-x-3'>{turtle.attributes[8]["value"]}</h2>
                                                                ) : (
                                                                    (turtle.name).includes('egg') === false ? (
                                                                        turtle.attributes[8]["value"] > 55 && turtle.attributes[8]["value"] <= 65 ? (
                                                                            <h2 className='text-purple-600 flex items-center turtlerarity bg-[rgba(235,235,235)] px-[6px] rounded-full font-bold absolute overflow-hidden text-center text-base xs:text-sm 2xs:text-xs translate-x-3'>{turtle.attributes[8]["value"]}</h2>
                                                                        ) : (
                                                                            (turtle.name).includes('egg') === false ? (
                                                                                turtle.attributes[8]["value"] > 65 ? (
                                                                                    <h2 className='text-yellow-600 flex items-center turtlerarity bg-[rgba(235,235,235)] px-[6px] rounded-full font-bold absolute overflow-hidden text-center text-base xs:text-sm 2xs:text-xs translate-x-3'>{turtle.attributes[8]["value"]}</h2>
                                                        ) : (<></>)) : (<></>))) : (<></>))) : (<></>))) : (<></>))) : (<></>)}


                                            <h2 className='text-center text-base sm:text-lg md:text-xl'>#{turtle.tokenId}</h2>
                                            {(turtle.name).includes('Tubby Turtle') === true ? (
                                            <h2 className='mt-[2px] text-[rgb(90,90,90)] turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[1em]'>{turtle.name}</h2>
                                            ) : (<h2 className='mt-[2px] bg-[rgb(199,223,255)] border-2 border-[rgb(159,201,255)] xs:mx-[2px] s:mx-[3px] mx-[5px] sm:mx-[7px] md:mx-[9px] rounded-lg turtlename overflow-hidden flex items-center justify-center text-center leading-[1.4rem] text-[1em]'>{turtle.name}</h2>)}
                                        </div>
                                    </div>
                                </div>
                            )))}
                            
                        </div>
                        </div>
                    <div className='w-full z-[0]' id='viewerbodyusersturtles'>
                        

                        {loading ? 
                        <div className='flex flex-col justify-center items-center mb-4'>
                            <p className='my-2 text-xl'>Please wait, loading in turtles...</p>
                            <PulseLoader loading={loading} color="#4d7cff" size={25} />
                        </div>
                        : (
                        <div className='flex justify-center my-3'>
                            <button onClick={loadMoreTurtles} className='flex justify-center rounded-md w-[250px] h-[40px] text-xl bg-blue-300'>
                                <div className='mx-auto my-auto flex flex-row'>
                                    <div>
                                    Load more turtles
                                    </div>
                                    <div>
                                        <AiOutlineReload className='mt-1 ml-[5px]' />
                                    </div>
                                </div>
                            </button>
                        </div>
                        )}
                    </div>
                </div>
            </div>
            <footer className='mb-[20px] flex justify-center relative sm:bottom-1 md:bottom-3 lg:bottom-5 z-50 w-full h-[100px]'>
                <div className='mb-[20px] flex flex-row w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] h-full place-content-around'>
                    <a href='https://twitter.com/TurtlesTubby' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={twitterlogo} alt="Twitter logo" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Twitter</h1>
                    </a>

                    <a href='https://energywebnfts.com/markets' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(54,151,44,0.9)] transition-all border-[rgba(44,124,37,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={marketicon} alt="NFT marketplaces icon" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Markets</h1>
                    </a>

                    <a href='https://t.me/susu_market' className='rounded-xl md:rounded-3xl pt-1 md:py-2 my-auto w-1/4 md:w-1/5 bg-[rgba(50,168,241,0.9)] transition-all border-[rgba(41,136,196,0.9)] border-b-[5px] md:border-b-[8px] active:border-b-[2px] md:active:border-b-[4px] active:translate-y-1 md:active:translate-y-2'>
                        <img className='mx-auto w-[30px] h-[30px] md:w-[35px] md:h-[35px] lg:w-[40px] lg:h-[40px] invert' src={telegramlogo} alt="Telegram logo" />
                        <h1 className='font-bold flex justify-center text-base sm:text-lg md:text-xl lg:text-2xl'>Telegram</h1>
                    </a>
                </div>
            </footer>
        </div>
    )};

export default AllTurtles;