import { useSelector, useDispatch } from "react-redux";
import { fetchData } from '../redux/data/dataActions';
import { useEffect, useState } from 'react'
import turtlewalk from '../assets/images/turtlewalk.gif'
import walleticon from '../assets/images/walleticon.png'
import turtlestationary from '../assets/images/turtle-stationary.png'
import bgimg from '../assets/images/mintbg.png';
import { connect } from '../redux/blockchain/blockchainActions';
import TurtleMetadata from '../Nftdata/TurtleMetadata'
import PulseLoader from 'react-spinners/PulseLoader'
import { AiOutlineReload } from 'react-icons/ai'

const TurtleTransfer = () => {
    window.onerror = function() {
        window.reload();
    }
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    window.addEventListener('load', function () {
        startApp();
    })
    async function startApp() {
        window.ethereum.sendAsync({
            method: "eth_accounts",
            params: [],
            jsonrpc: "2.0",
            id: new Date().getTime()
        }, function (error, result) {
            if (result["result"] !== "") dispatch(connect());
        });
    }

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        STAKINGCONTRACT_ADDRESS: "",
        SCAN_LINK: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        NFT_NAME: "",
        SYMBOL: "",
        MAX_SUPPLY: 1,
        WEI_COST: 0,
        DISPLAY_COST: 0,
        GAS_LIMIT: 0,
        MARKETPLACE: "",
        MARKETPLACE_LINK: ""
    });

    const getConfig = async () => {
        const configResponse = await fetch('/config/config.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();
    }, []);

    const delay = ms => new Promise(res => setTimeout(res, ms));

    async function transferanimation() {
        let turtleidinput = document.getElementById('turtleidinput').value
        let turtleaddressinput = document.getElementById('turtleaddressinput').value

        //blockchain.smartContract.methods.approve(CONFIG.CONTRACT_ADDRESS, turtleidinput)
        //    .send({
        //        to: CONFIG.CONTRACT_ADDRESS,
        //        from: blockchain.account,
        //    })
        blockchain.smartContract.methods.safeTransferFrom(blockchain.account, turtleaddressinput, turtleidinput)
            .send({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
            })
            .then(async (receipt) => {
                let turtlewallet = document.getElementById('turtlewallet')
                turtlewallet.style.animation = "";
                turtlewallet.style.animation = "walk 7s linear forwards";
                turtlewallet.src = turtlewalk;
                let txreceipt = document.getElementById('txreceipt')
                txreceipt.innerHTML = 'Transaction hash: ' + String(receipt["transactionHash"].slice(0, 10)) + '...' + String(receipt["transactionHash"].slice(-10));
                txreceipt.href = `https://explorer.energyweb.org/tx/${receipt["transactionHash"]}`;
                let animationcontainer = document.getElementById('animationcontainer')
                animationcontainer.style.display = 'flex';
                await delay(7200);
                animationcontainer.style.display = 'none';
            })
    }

    async function getOwnerIDS() {
        let arrayofids = [];
        for (let id = 0; id < data["turtleOwner"].length; id++) {
            let element = data["turtleOwner"][id];
            arrayofids.push(element);
        }
        arrayofids = arrayofids.sort(function (a, b) {  return a - b;  });
        console.log(arrayofids)
        let arrayhtml = '';
        for (let id = 0; id < arrayofids.length; id++) {
            let htmlstring = `<div class='m-[3px]'><p class='flex justify-center text-center'>${TurtleMetadata[arrayofids[id]-1]["tokenId"]}</p><img class='rounded-md w-14 h-14' src=${TurtleMetadata[arrayofids[id]-1]["image"]} /></div>`
            arrayhtml += htmlstring;
        }

        let ownedturtlescontainer = document.getElementById('ownedturtlescontainer')
        ownedturtlescontainer.innerHTML = arrayhtml
    }

    //console.log(data["turtleOwner"])

    return (
        <div>
        <div className="rounded-2xl flex bg-[rgb(231,241,255)] flex-col mx-auto w-[94%] sm:w-[88%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[50%] mt-[95px] sm:mt-[110px] md:mt-[135px] lg:mt-[160px] mb-6 sm:mb-12 md:mb-20 lg:mb-28">
            <img className='fixed top-0 left-0 w-full h-full object-cover z-[-1]' src={bgimg} alt="Background image" />

            {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                <div className='flex flex-col justify-center min-h-[150px]'>
                    <button className='mt-3 mb-1 flex pl-1 pr-2 mx-auto justify-center rounded-2xl items-center
                            bg-green-500 border-b-[5px] border-[rgb(24,136,65)] hover:brightness-[1.05] active:border-b-[3px] active:translate-y-[6px] active:mt-[2px]' onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                            getData();
                        }}>

                        <svg width="50px" height="50px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                            <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                        </svg>
                        Connect
                    </button>

                    {blockchain.errorMsg !== "" ? (
                        <>
                            <h2 className='text-center mb-2 errormsg'>{blockchain.errorMsg}</h2>
                        </>
                    ) : null}
                </div>
            ) : (
                <div className='w-[calc(100%-40px)] bg-white mx-5 mt-5 mb-5 py-4 md:py-8 px-4 justify-center rounded-lg'>
                    <h1 className='text-center text-lg md:text-xl lg:text-2xl font-bold mb-3'>Tubby Turtle Transfer</h1>
                    {data["turtleOwner"].length === 0 ? (
                        <div className='flex justify-center'>
                            <PulseLoader color="#4d7cff" />
                        </div>
                    ) : (
                        <div className='flex justify-center w-full'>
                            <button className='flex justify-center flex-row bg-[#C9E2FF] hover:brightness-110 rounded-md w-[130px] py-[2px]' onClick={getOwnerIDS}><AiOutlineReload className='my-auto mr-[3px] -translate-y-[1px]' />Load Turtles</button>
                        </div>
                    )}
                    <div className='flex flex-wrap justify-center' id='ownedturtlescontainer'>

                    </div>
                    <div className='flex mx-auto flex-col w-full my-2'>
                        <div className='text-center'>
                            <p>Make sure you put in the correct turtle ID and address where you want to send your turtle to.</p>
                            {/*
                            <p>Don't use this if you want to stake a turtle, staking can be done on the <Link className='text-[#2D286B] hover:brightness-125 active:brightness-200 underline' to='/sea/my'>'My turtles page'</Link></p>
                            */}
                        </div>
                        <div className='flex flex-row mx-auto'>
                            <label className='w-[125px] flex justify-end items-center mr-1'>Turtle ID:</label>
                            <div className='w-[140px] sm:w-[400px] m-[2px]'>
                                <input id='turtleidinput' className='w-[50px] bg-[#E7F1FF] px-1'></input>
                            </div>
                        </div>
                        <div className='flex flex-row mx-auto'>
                            <label className='w-[125px] flex justify-end items-center mr-1'>Turtle new home:</label>
                            <input id='turtleaddressinput' className='w-[140px] sm:w-[400px] m-[2px] bg-[#E7F1FF] px-1'></input>
                        </div>
                        <div className='flex justify-center w-full'>
                            <a target='_blank' href='https://explorer.energyweb.org/' className='text-[#2D286B] hover:brightness-125 active:brightness-200 underline overflow-hidden' id='txreceipt'></a>
                        </div>
                        <button className='bg-[#C9E2FF] mx-auto mt-3 hover:brightness-110 rounded-md w-[85px] py-[2px]' onClick={transferanimation}>Transfer</button>
                    </div>
                    <div id='animationcontainer' className='mt-2 hidden w-full flex-col'>
                        <div className='flex flex-row justify-center'>
                            <img className='my-auto pl-1 h-10 md:h-12 lg:h-14' src={walleticon} alt="" />
                            <div className='w-[200px] flex justify-center'>
                                <img id='turtlewallet' className='relative w-16 h-16 md:w-20 md:h-20 -translate-x-[70px]' src={turtlestationary} alt="" />
                            </div>
                            <img className='my-auto pr-1 h-10 md:h-12 lg:h-14' src={walleticon} alt="" />
                        </div>
                        <div className='mt-1 flex justify-center text-center'>
                            <p className='text-center'>Your Tubby Turtle is being transferred to a new home...</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
        </div>
    );
}

export default TurtleTransfer;