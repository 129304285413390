import TurtleMetadata from '../Nftdata/TurtleMetadata'
import Background from '../assets/images/grassfield.png'
import elementicon1 from '../assets/images/elementsymbols/Fire.png'
import elementicon2 from '../assets/images/elementsymbols/Water.png'
import elementicon3 from '../assets/images/elementsymbols/Poison.png'
import elementicon4 from '../assets/images/elementsymbols/Electric.png'
import elementicon5 from '../assets/images/elementsymbols/Frost.png'
import elementicon6 from '../assets/images/elementsymbols/Rock.png'
import elementicon7 from '../assets/images/elementsymbols/Nature.png'
import PulseLoader from 'react-spinners/PulseLoader'
import seaWeed from '../assets/images/seaWeed.png'
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from '../redux/data/dataActions';
import { connect } from '../redux/blockchain/blockchainActions';
import { db, dbThree } from '../firebase';
import { onValue, ref, set } from 'firebase/database';
import Draggable from 'react-draggable';
import turtleEating from '../assets/sounds/eatingSound.mp3';
import shelllogo from '../assets/images/shellcoin.png';
import store from '../redux/store';

function TurtleRenderer() {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const data = useSelector((state) => state.data);

    const [pagenumber, setPagenumber] = useState(1)
    const [Turtles, setTurtles] = useState([])
    const [turtlesloaded, setTurtlesloaded] = useState(false)

    useEffect(() => {
        let pathname = window.location.pathname;
        let pageid = pathname.substring(1);
        setPagenumber(pageid)
        onValue(ref(db, `/${pageid}` ), snapshot => {
            const firebasedata = snapshot.val();
            setTurtles(firebasedata);
        })
        if (Turtles !== []) {
            setTurtlesloaded(true)
        }
    }, []);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
            dispatch(fetchData(blockchain.account));
        }
    };

    useEffect(() => {
        getData();
    }, [blockchain.account]);

    const [hatched, setHatched] = useState(false);

    const [attribt0, setattrib0] = useState('');
    const [attribt1, setattrib1] = useState('');
    const [attribt2, setattrib2] = useState('');
    const [attribt3, setattrib3] = useState('');
    const [attribt4, setattrib4] = useState('');
    const [attribt5, setattrib5] = useState('');
    const [attribt6, setattrib6] = useState('');

    const [attribt0Color, setattrib0Color] = useState('');
    const [attribt1Color, setattrib1Color] = useState('');
    const [attribt2Color, setattrib2Color] = useState('');
    const [attribt3Color, setattrib3Color] = useState('');
    const [attribt4Color, setattrib4Color] = useState('');
    const [attribt5Color, setattrib5Color] = useState('');
    const [attribt6Color, setattrib6Color] = useState('');

    useEffect(() => {
        if (turtlesloaded === true) {
            if (Turtles["attributes"].length > 2) {
                setHatched(true);

            let common0 = ['Blue','Cyan','Green','Orange','Yellow','Red']
            let uncommon0 = ['Pink','Purple']
            let rare0 = ['Underwater','Stars','Northpole']
            let epic0 = ['Island','Valley','Lake']
            let legendary0 = ['Moon','Galaxy','Night']

            let common1 = ['Blue','Green','Red','Orange','Purple','Cyan','Yellow']
            let uncommon1 = ['Light blue','Light green','Light red','Light orange','Light purple','Light cyan','Light yellow']
            let rare1 = ['Dark blue','Dark green','Dark red','Dark orange','Dark purple','Dark cyan','Dark yellow']
            let epic1 = ['Black shell','White shell']
            let legendary1 = ['Bowser shell','Rock']

            let common2 = ['Blue','Green','Red','Orange','Purple','Cyan','Yellow']
            let uncommon2 = ['Light blue','Light green','Light red','Light orange','Light purple','Light cyan','Light yellow']
            let rare2 = ['Dark blue','Dark green','Dark red','Dark orange','Dark purple','Dark cyan','Dark yellow']
            let epic2 = ['Black shellbrim','White shellbrim']
            let legendary2 = ['Bowser shellbrim','Frost']

            let common3 = ['Cute','Cute and blush']
            let uncommon3 = ['Closed','Closed and blush']
            let rare3 = ['Big','Big and blush','Funny','Funny and blush']
            let epic3 = ['Sunglasses','Bandana','3d glasses','blink','Blink and blush','Crossed out']
            let legendary3 = ['Blue laser','Red laser','Green laser','BURN BURN BURN']

            let common4 = ['No hat']
            let uncommon4 = ['Red beanie','Light gray beanie']
            let rare4 = ['Black beanie','Ricehat','Halo','Bear hat','Polar bear hat','Headphones','Flower']
            let epic4 = ['Wizard hat','Dark wizard hat','Crown','Frog hat','Glass bowl helmet','Gangster hat','White cat ears','Black cat ears','Dog ears','Devil horns']
            let legendary4 = ['Green partyhat','Purple partyhat','Red partyhat','White partyhat','Yellow partyhat','Blue partyhat','Bitcoin face tattoo', 'Laurel leaves']

            let common5 = ['Open']
            let uncommon5 = ['Big']
            let rare5 = ['Smiley']
            let epic5 = ['Funny','Smile']
            let legendary5 = ['Bubbles']

            let common6 = ['No artifact']
            let uncommon6 = ['Green konpeito','Pink konpeito','Yellow konpeito']
            let rare6 = ['EnergyWeb']
            let epic6 = ['Bitcoin','Ethereum']
            let legendary6 = ['Susu','EnergyWeb DOGE','Ocean protocol','Polkadot','Cryptosoots','Smudge','Poison bottle','Shell']

            let attrib0 = Turtles.attributes[0].value
            let attrib1 = Turtles.attributes[2].value
            let attrib2 = Turtles.attributes[3].value
            let attrib3 = Turtles.attributes[4].value
            let attrib4 = Turtles.attributes[6].value
            let attrib5 = Turtles.attributes[5].value
            let attrib6 = Turtles.attributes[7].value
            if (common0.includes(attrib0)) {setattrib0('Common');setattrib0Color('#D8D9D7');}
            if (uncommon0.includes(attrib0)) {setattrib0('Uncommon');setattrib0Color('#72C649');}
            if (rare0.includes(attrib0)) {setattrib0('Rare');setattrib0Color('#6B9AE7');}
            if (epic0.includes(attrib0)) {setattrib0('Epic');setattrib0Color('#9973F4');}
            if (legendary0.includes(attrib0)) {setattrib0('Legendary');setattrib0Color('#E8B84A');}

            if (common1.includes(attrib1)) {setattrib1('Common');setattrib1Color('#D8D9D7');}
            if (uncommon1.includes(attrib1)) {setattrib1('Uncommon');setattrib1Color('#72C649');}
            if (rare1.includes(attrib1)) {setattrib1('Rare');setattrib1Color('#6B9AE7');}
            if (epic1.includes(attrib1)) {setattrib1('Epic');setattrib1Color('#9973F4');}
            if (legendary1.includes(attrib1)) {setattrib1('Legendary');setattrib1Color('#E8B84A');}

            if (common2.includes(attrib2)) {setattrib2('Common');setattrib2Color('#D8D9D7');}
            if (uncommon2.includes(attrib2)) {setattrib2('Uncommon');setattrib2Color('#72C649');}
            if (rare2.includes(attrib2)) {setattrib2('Rare');setattrib2Color('#6B9AE7');}
            if (epic2.includes(attrib2)) {setattrib2('Epic');setattrib2Color('#9973F4');}
            if (legendary2.includes(attrib2)) {setattrib2('Legendary');setattrib2Color('#E8B84A');}

            if (common3.includes(attrib3)) {setattrib3('Common');setattrib3Color('#D8D9D7');}
            if (uncommon3.includes(attrib3)) {setattrib3('Uncommon');setattrib3Color('#72C649');}
            if (rare3.includes(attrib3)) {setattrib3('Rare');setattrib3Color('#6B9AE7');}
            if (epic3.includes(attrib3)) {setattrib3('Epic');setattrib3Color('#9973F4');}
            if (legendary3.includes(attrib3)) {setattrib3('Legendary');setattrib3Color('#E8B84A');}

            if (common4.includes(attrib4)) {setattrib4('Common');setattrib4Color('#D8D9D7');}
            if (uncommon4.includes(attrib4)) {setattrib4('Uncommon');setattrib4Color('#72C649');}
            if (rare4.includes(attrib4)) {setattrib4('Rare');setattrib4Color('#6B9AE7');}
            if (epic4.includes(attrib4)) {setattrib4('Epic');setattrib4Color('#9973F4');}
            if (legendary4.includes(attrib4)) {setattrib4('Legendary');setattrib4Color('#E8B84A');}

            if (common5.includes(attrib5)) {setattrib5('Common');setattrib5Color('#D8D9D7');}
            if (uncommon5.includes(attrib5)) {setattrib5('Uncommon');setattrib5Color('#72C649');}
            if (rare5.includes(attrib5)) {setattrib5('Rare');setattrib5Color('#6B9AE7');}
            if (epic5.includes(attrib5)) {setattrib5('Epic');setattrib5Color('#9973F4');}
            if (legendary5.includes(attrib5)) {setattrib5('Legendary');setattrib5Color('#E8B84A');}

            if (common6.includes(attrib6)) {setattrib6('Common');setattrib6Color('#D8D9D7');}
            if (uncommon6.includes(attrib6)) {setattrib6('Uncommon');setattrib6Color('#72C649');}
            if (rare6.includes(attrib6)) {setattrib6('Rare');setattrib6Color('#6B9AE7');}
            if (epic6.includes(attrib6)) {setattrib6('Epic');setattrib6Color('#9973F4');}
            if (legendary6.includes(attrib6)) {setattrib6('Legendary');setattrib6Color('#E8B84A');}
            }
        }
        //getturtlename();
    }, [Turtles]);

    function requestTurtleName(turtleId, name) {
        set(ref(dbThree, '/' + turtleId), {
            newName: name
        });
    }

    const [pageloaded, setPageloaded] = useState(false)
    function changeTurtleName() {
        let newName = document.getElementById('textInputTurtle').value
        let pathname = window.location.pathname
        let pageid = pathname.substring(1)

        console.log(pageid)
        console.log(String(newName))

        blockchain.smartContract.methods.changeTurtleName(parseInt(pageid), String(newName))
            .send({
                to: "0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7",
                from: blockchain.account
            }).then((receipt) => {
                console.log(receipt)
                requestTurtleName(parseInt(pageid), String(newName))
                document.getElementById('tubbyTurtleName').innerText = newName;
            }
        )
    }

    function approveShellsToTubbyTurtles() {
        let approveAmount = '75000000000000000000000';

        blockchain.stakingSmartContract.methods.approve("0x5A547Ad0cE7140110aE945F00b7D8dF6f58257d7", approveAmount)
            .send({
                to: "0xc6c6239614723298591f16bB2f779C9199b5AB1a",
                from: blockchain.account,
            })
    }

    window.addEventListener('load', function () {
        startApp();
        setPageloaded(true);
    })
    async function startApp() {
        window.ethereum.sendAsync({
            method: "eth_accounts",
            params: [],
            jsonrpc: "2.0",
            id: new Date().getTime()
        }, function (error, result) {
            if (result["result"] !== "") dispatch(connect());
        });
    }

    let getElementIcon = window.location.pathname;
    getElementIcon = getElementIcon.substring(1);
    getElementIcon = TurtleMetadata[getElementIcon-1]["attributes"][1]["value"]
    if (getElementIcon === 'Fire') {getElementIcon = elementicon1}
    if (getElementIcon === 'Water') {getElementIcon = elementicon2}
    if (getElementIcon === 'Poison') {getElementIcon = elementicon3}
    if (getElementIcon === 'Electric') {getElementIcon = elementicon4}
    if (getElementIcon === 'Frost') {getElementIcon = elementicon5}
    if (getElementIcon === 'Rock') {getElementIcon = elementicon6}
    if (getElementIcon === 'Nature') {getElementIcon = elementicon7}

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const trackPos = (data) => {
        setPosition({ x: data.x, y: data.y });
    };

    const [position2, setPosition2] = useState({ x: 0, y: 0 });
    const trackPos2 = (data2) => {
        setPosition2({ x: data2.x, y: data2.y });
    };

    const [position3, setPosition3] = useState({ x: 0, y: 0 });
    const trackPos3 = (data3) => {
        setPosition3({ x: data3.x, y: data3.y });
    };

    const [position4, setPosition4] = useState({ x: 0, y: 0 });
    const trackPos4 = (data4) => {
        setPosition4({ x: data4.x, y: data4.y });
    };

    const turtleImgRef = useRef();

    const seaWeedRef = useRef();
    const seaWeedRef2 = useRef();
    const seaWeedRef3 = useRef();
    const seaWeedRef4 = useRef();

    const [seaWeedEaten1, setSeaWeedEaten1] = useState(false)
    const [seaWeedEaten2, setSeaWeedEaten2] = useState(false)
    const [seaWeedEaten3, setSeaWeedEaten3] = useState(false)
    const [seaWeedEaten4, setSeaWeedEaten4] = useState(false)

    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function playAudio1(){
        if (seaWeedEaten1 === false) {
            document.getElementById("seaWeedAudio1").play();
            await sleep(1000)
            document.getElementById("seaWeedAudio1").remove();
            setSeaWeedEaten1(true)
        }
    }
    async function playAudio2(){
        if (seaWeedEaten2 === false) {
            document.getElementById("seaWeedAudio2").play();
            await sleep(1000);
            document.getElementById("seaWeedAudio2").remove();
            setSeaWeedEaten2(true);
        }
    }
    async function playAudio3(){
        if (seaWeedEaten3 === false) {
            document.getElementById("seaWeedAudio3").play();
            await sleep(1000)
            document.getElementById("seaWeedAudio3").remove();
            setSeaWeedEaten3(true)
        }
    }
    async function playAudio4(){
        if (seaWeedEaten4 === false) {
            document.getElementById("seaWeedAudio4").play();
            await sleep(1000)
            document.getElementById("seaWeedAudio4").remove();
            setSeaWeedEaten4(true)
        }
    }

    useEffect(() => {
        if (pageloaded === true) {
            if (seaWeedEaten1 === false) {
                if (turtleImgRef.current.getBoundingClientRect()["left"] + 60 < seaWeedRef.current.getBoundingClientRect()["left"]) {
                    if (seaWeedRef.current.getBoundingClientRect()["right"] < turtleImgRef.current.getBoundingClientRect()["right"] - 150) {
                        if (seaWeedRef.current.getBoundingClientRect()["top"] > turtleImgRef.current.getBoundingClientRect()["top"] + 60) {
                            if (seaWeedRef.current.getBoundingClientRect()["bottom"] < turtleImgRef.current.getBoundingClientRect()["bottom"] - 150) {
                                document.getElementById('seaWeed1').style.visibility = 'hidden';
                                playAudio1()
                            }
                        }
                    }
                }
            }
            if (seaWeedEaten2 === false) {
                if (turtleImgRef.current.getBoundingClientRect()["left"] + 60 < seaWeedRef2.current.getBoundingClientRect()["left"]) {
                    if (seaWeedRef2.current.getBoundingClientRect()["right"] < turtleImgRef.current.getBoundingClientRect()["right"] - 150) {
                        if (seaWeedRef2.current.getBoundingClientRect()["top"] > turtleImgRef.current.getBoundingClientRect()["top"] + 60) {
                            if (seaWeedRef2.current.getBoundingClientRect()["bottom"] < turtleImgRef.current.getBoundingClientRect()["bottom"] - 150) {
                                document.getElementById('seaWeed2').style.visibility = 'hidden';
                                playAudio2()
                            }
                        }
                    }
                }
            }
            if (seaWeedEaten3 === false) {
                if (turtleImgRef.current.getBoundingClientRect()["left"] + 60 < seaWeedRef3.current.getBoundingClientRect()["left"]) {
                    if (seaWeedRef3.current.getBoundingClientRect()["right"] < turtleImgRef.current.getBoundingClientRect()["right"] - 150) {
                        if (seaWeedRef3.current.getBoundingClientRect()["top"] > turtleImgRef.current.getBoundingClientRect()["top"] + 60) {
                            if (seaWeedRef3.current.getBoundingClientRect()["bottom"] < turtleImgRef.current.getBoundingClientRect()["bottom"] - 150) {
                                document.getElementById('seaWeed3').style.visibility = 'hidden';
                                playAudio3()
                            }
                        }
                    }
                }
            }
            if (seaWeedEaten4 === false) {
                if (turtleImgRef.current.getBoundingClientRect()["left"] + 60 < seaWeedRef4.current.getBoundingClientRect()["left"]) {
                    if (seaWeedRef4.current.getBoundingClientRect()["right"] < turtleImgRef.current.getBoundingClientRect()["right"] - 150) {
                        if (seaWeedRef4.current.getBoundingClientRect()["top"] > turtleImgRef.current.getBoundingClientRect()["top"] + 60) {
                            if (seaWeedRef4.current.getBoundingClientRect()["bottom"] < turtleImgRef.current.getBoundingClientRect()["bottom"] - 150) {
                                document.getElementById('seaWeed4').style.visibility = 'hidden';
                                playAudio4()
                            }
                        }
                    }
                }
            }
        }
    }, [position, position2, position3, position4]);

    async function previousPage() {
        if (parseInt(pagenumber) > 1) {
            window.location.href = `https://www.tubbyturtles.com/${parseInt(pagenumber)-1}`;
        }
    }

    async function nextPage() {
        window.location.href = `https://www.tubbyturtles.com/${parseInt(pagenumber)+1}`;
    }

    return ( 
    <div style={{backgroundImage: `url(${Background})`}} className='shadow-[0_0_150px_rgba(0,0,0,0.6)_inset] min-h-[100vh] bg-[#92C16F] bg-no-repeat 2xl:bg-cover'>
        <div className='overflow-hidden h-auto w-auto flex flex-col md2:flex-row md2:px-8 lg:px-12 xl:px-16 2xl:px-28'>
            <div className='mt-[110px] md:mt-[130px] md2:mt-[150px] lg:mt-[170px] flex mx-auto sm:w-[65%]'>
                
                {turtlesloaded === false ? (
                    <PulseLoader className='m-auto' color="#4d7cff" size={25} />
                ) : (
                    <div>
                        <div className='relative z-1'>
                            <img ref={turtleImgRef} className='rounded-xl shadow-xl aspect-square max-h-[80vh]' style={{border: '10px solid white'}} src={Turtles["image"]} alt=""/>
                        </div>
                    </div>

                )} 
                
            </div>

            <button onClick={previousPage} className='px-2 py-1 rounded-lg bg-blue-300 fixed left-1 top-[50vh]'>←</button>
            <button onClick={nextPage} className='px-2 py-1 rounded-lg bg-blue-300 fixed right-1 top-[50vh]'>→</button>

            <div className='mx-auto w-[96%] sm:w-[90%] md:w-[80%] md2:w-[56%] xl:w-[45%] 2xl:w-[80%!important] md2:px-6 md2:mt-[140px]'>
                {blockchain.account !== "" && hatched === true && data !== [] && (data["turtleOwner"].includes(String(pagenumber)) === true || data["turtlesStaked"].includes(String(pagenumber)) === true) ? (
                    <div className='rounded-lg shadow-xl bg-[#e4a766] border-[calc(5px+0.5vw)] border-[#ce9251] mb-4 mt-4'>
                        <audio id='seaWeedAudio1' src={turtleEating}></audio>
                        <audio id='seaWeedAudio2' src={turtleEating}></audio>
                        <audio id='seaWeedAudio3' src={turtleEating}></audio>
                        <audio id='seaWeedAudio4' src={turtleEating}></audio>
                        <div className='flex flex-row'>
                        <Draggable defaultPosition={{x: 0, y: 0}} onDrag={(e, data) => trackPos(data)}>
                            <img id='seaWeed1' ref={seaWeedRef} draggable="false" className='select-none w-[15%] mx-[5%] md:w-[14%] md:mx-[5.5%] xl:w-[12%] xl:mx-[6.5%] my-1 sm:my-2 md:my-3' src={seaWeed} alt="seaWeed" />
                        </Draggable>
                        <Draggable defaultPosition={{x: 0, y: 0}} onDrag={(e, data2) => trackPos2(data2)}>
                            <img id='seaWeed2' ref={seaWeedRef2} draggable="false" className='select-none w-[15%] mx-[5%] md:w-[14%] md:mx-[5.5%] xl:w-[12%] xl:mx-[6.5%] my-1 sm:my-2 md:my-3' src={seaWeed} alt="seaWeed" />
                        </Draggable>
                        <Draggable defaultPosition={{x: 0, y: 0}} onDrag={(e, data3) => trackPos3(data3)}>
                            <img id='seaWeed3' ref={seaWeedRef3} draggable="false" className='select-none w-[15%] mx-[5%] md:w-[14%] md:mx-[5.5%] xl:w-[12%] xl:mx-[6.5%] my-1 sm:my-2 md:my-3' src={seaWeed} alt="seaWeed" />
                        </Draggable>
                        <Draggable defaultPosition={{x: 0, y: 0}} onDrag={(e, data4) => trackPos4(data4)}>
                            <img id='seaWeed4' ref={seaWeedRef4} draggable="false" className='select-none w-[15%] mx-[5%] md:w-[14%] md:mx-[5.5%] xl:w-[12%] xl:mx-[6.5%] my-1 sm:my-2 md:my-3' src={seaWeed} alt="seaWeed" />
                        </Draggable>
                        </div>
                    </div>
                    ) : (
                        <></>
                    )
                }
                <div className='rounded-lg shadow-xl bg-[#e4a766] border-[calc(5px+0.5vw)] border-[#ce9251] mb-4 mt-4'>
                        <div className='flex flex-col justify-center items-center w-full'>
                            <div className='p-[5px] sm:p-[8px] md:p-[12px]'>

                                <div className='flex flex-col 2xl:flex-row'>
                                        <div className='flex flex-row'>
                                            <p className='flex my-auto text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold mr-2'>Name: </p>
                                    
                                            <div className='flex my-auto flex-col text-lg sm:text-xl md:text-2xl lg:text-3xl'>
                                            {hatched === true ? (
                                                <p id='tubbyTurtleName'>{Turtles['name']}</p>
                                            ) : (
                                                <p>Tubby Turtle egg #{pagenumber}</p>
                                            )}
                                        </div>
                                        </div>
                                        {blockchain.account === "" ||
                        blockchain.smartContract === null ? (
                    <div className='h-full w-full flex justify-center items-center'>
                        <div className='mb-4 flex flex-col justify-center items-center'>
                            <button className='mt-3 mb-1 flex pl-1 pr-2 mx-auto justify-center rounded-2xl items-center
                            bg-[#45e07e] border-[#28be5f] border-b-[4px] hover:brightness-[1.05] active:border-b-[2px] active:translate-y-[6px] active:mt-[2px]' onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(connect());
                                    getData();
                                }}>

                                <svg width="50px" height="50px" className="metamaskicon" strokeLinejoin="round" viewBox="0 0 318.6 318.6" xmlns="http://www.w3.org/2000/svg">
                                    <path d="m274.1 35.5-99.5 73.9 18.4-43.6z" fill="#e2761b" stroke="#e2761b"></path><g fill="#e4761b" stroke="#e4761b"><path d="m44.4 35.5 98.7 74.6-17.5-44.3zm193.9 171.3-26.5 40.6 56.7 15.6 16.3-55.3z"></path><path d="m33.9 207.7 16.2 55.3 56.7-15.6-26.5-40.6zm69.7-69.5-15.8 23.9 56.3 2.5-2-60.5z"></path><path d="m214.9 138.2-39-34.8-1.3 61.2 56.2-2.5zm-108.1 109.2 33.8-16.5-29.2-22.8zm71.1-16.5 33.9 16.5-4.7-39.3z"></path></g><path d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zm-105 0l31.5 14.9-.2-9.3 2.5-22.1z" fill="#d7c1b3" stroke="#d7c1b3"></path><path d="m138.8 193.5-28.2-8.3 19.9-9.1zm40.9 0 8.3-17.4 20 9.1z" fill="#233447" stroke="#233447"></path><path d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zm23.8-44.7l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zm-120.2 23.1l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z" fill="#cd6116" stroke="#cd6116"></path><path d="m87.8 162.1 23.6 46-0.8-22.9zm120.3 23.1-1 22.9 23.7-46zm-64-20.6-5.3 28.9 6.6 34.1 1.5-44.9zm30.5 0-2.7 18 1.2 45 6.7-34.1z" fill="#e4751f" stroke="#e4751f"></path><path d="m179.8 193.5-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zm-69.2-8.3 0.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z" fill="#f6851b" stroke="#f6851b"></path><path d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z" fill="#c0ad9e" stroke="#c0ad9e"></path><path d="m177.9 230.9-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z" fill="#161616" stroke="#161616"></path><path d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z" fill="#763d16" stroke="#763d16"></path><path d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zm-163.6-15.3l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zm71 26.4l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z" fill="#f6851b" stroke="#f6851b"></path>
                                </svg>
                                Connect
                            </button>

                            {blockchain.errorMsg !== "" ? (
                                <>
                                    <h2 className='text-center mb-2 errormsg'>{blockchain.errorMsg}</h2>
                                </>
                            ) : null}
                        </div>
                        </div>
                        ) : (
                            data["loading"] === false ? (
                                <div className='my-auto flex flex-col 2xl:mr-0 2xl:ml-auto'>
                                    <div className='2xl:mr-0 2xl:ml-auto h-[44px]'>
                                        <button className='bg-[#45e07e] border-[#28be5f] hover:brightness-110 border-b-[3px] active:border-b-[1px] active:translate-y-[2px] text-lg sm:text-xl rounded-xl py-[4px] px-[8px] flex flex-row items-center' onClick={approveShellsToTubbyTurtles}>Approve <img className='ml-[5px] my-auto w-7 h-7' src={shelllogo} /></button>
                                    </div>
                                    <div>
                                        <p>You can still change <span>{(data.approveAllowance)/1e+18/7500}</span> more turtle names</p>
                                    </div>
                                    <div>
                                        <input className='rounded-md sm:w-[252px] mb-2 px-[10px] py-[2px]' id='textInputTurtle' type="text" name='name' placeholder={'New turtle name (max. 20 char.)'} minLength='1' maxLength='20' required autoFocus autoComplete='off'/>
                                        <button className='bg-[#45e07e] rounded-lg ml-2 p-1 hover:brightness-110' id='submitNameButton' onClick={changeTurtleName}>Submit</button>
                                    </div>
                                </div>
                                ) : (
                                    <PulseLoader className='flex my-auto ml-3' color="#DB7B43" size={16} />
                                ) 
                        )}
                                </div>
                                
                                <div className='mt-2'>
                                    {hatched === true ? (
                                        <p className='lg:text-[18px] md:text-[17px]'><span className='lg:text-[18px] md:text-[17px] font-bold'>Description: </span>{Turtles.description}</p>
                                        ) : (
                                            <p className='lg:text-[18px] md:text-[17px]'><span className='lg:text-[18px] md:text-[17px] font-bold'>Description: </span>{TurtleMetadata[pagenumber].description}</p>  
                                        )}

                                    <div className='lg:text-[18px] md:text-[17px] flex flex-row items-center'>
                                        <p className='mr-[0.35em] font-bold'>Element:</p>
                                        {turtlesloaded === true && hatched === true ? (
                                            <div className='flex flex-row'>
                                            <p id='elementtext' className='flex items-center mr-[0.35em] font-bold'>{Turtles["attributes"][1]["value"]}</p>
                                            {/*
                                            <img className='w-12 h-12 md:w-14 md:h-14 lg:w-16 lg:h-16' src={getElementIcon}></img>
                                            */}
                                            </div>
                                        ) :
                                        (
                                            <></>
                                        )}
                                    </div>
                                    {hatched === true ? (
                                    <div className='flex flex-row mt-4'>
                                            <div className='2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] 2xs:w-[90px] 3xs:w-[82px] 4xs:w-[77px] flex flex-col mr-1 sm:mr-2 w-[105px]'>
                                            <p className='lg:h-[31px] md:h-[29.5px] h-[28px] 2xs:h-[26.5px] 3xs:h-[25px] 4xs:h-[24.25px] font-bold'>Background:</p>
                                            <p className='lg:h-[31px] md:h-[29.5px] h-[28px] 2xs:h-[26.5px] 3xs:h-[25px] 4xs:h-[24.25px] font-bold'>Shell:</p>
                                            <p className='lg:h-[31px] md:h-[29.5px] h-[28px] 2xs:h-[26.5px] 3xs:h-[25px] 4xs:h-[24.25px] font-bold'>Shellbrim:</p>
                                            <p className='lg:h-[31px] md:h-[29.5px] h-[28px] 2xs:h-[26.5px] 3xs:h-[25px] 4xs:h-[24.25px] font-bold'>Eyes:</p>
                                            <p className='lg:h-[31px] md:h-[29.5px] h-[28px] 2xs:h-[26.5px] 3xs:h-[25px] 4xs:h-[24.25px] font-bold'>Hat:</p>
                                            <p className='lg:h-[31px] md:h-[29.5px] h-[28px] 2xs:h-[26.5px] 3xs:h-[25px] 4xs:h-[24.25px] font-bold'>Mouth:</p>
                                            <p className='lg:h-[31px] md:h-[29.5px] h-[28px] 2xs:h-[26.5px] 3xs:h-[25px] 4xs:h-[24.25px] font-bold'>Artifact:</p>
                                            <p className='lg:h-[31px] md:h-[29.5px] h-[28px] 2xs:h-[26.5px] 3xs:h-[25px] 4xs:h-[24.25px] font-bold'>Rarity score:</p>
                                        </div>
                                        <div className='flex flex-col'>
                                            <div className='flex flex-row'>
                                                <p className='lg:text-[18px] md:text-[17px] mr-[5px] 2xs:mr-[3px] 3xs:mr-[2px] 2xs:text-[15px] 3xs:text-[14px] 2xs:w-[130px] 3xs:w-[112px] 4xs:w-[107px] whitespace-nowrap overflow-hidden w-[150px] md:w-[175px]'>{Turtles.attributes[0].value}</p>
                                                <p className='lg:text-[18px] md:text-[17px] 2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] px-[5px] my-[2px] bg-[#c4864c] rounded-2xl text-center w-[110px] 2xs:w-[95px] 3xs:w-[85px] 4xs:w-[81px]' style={{color:attribt0Color}}>{attribt0}</p>
                                            </div>
                                            <div className='flex flex-row'>
                                                <p className='lg:text-[18px] md:text-[17px] mr-[5px] 2xs:mr-[3px] 3xs:mr-[2px] 2xs:text-[15px] 3xs:text-[14px] 2xs:w-[130px] 3xs:w-[112px] 4xs:w-[107px] whitespace-nowrap overflow-hidden w-[150px] md:w-[175px]'>{Turtles.attributes[2].value}</p>
                                                <p className='lg:text-[18px] md:text-[17px] 2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] px-[5px] my-[2px] bg-[#c4864c] rounded-2xl text-center w-[110px] 2xs:w-[95px] 3xs:w-[85px] 4xs:w-[81px]' style={{color:attribt1Color}}>{attribt1}</p>
                                            </div>
                                            <div className='flex flex-row'>
                                                <p className='lg:text-[18px] md:text-[17px] mr-[5px] 2xs:mr-[3px] 3xs:mr-[2px] 2xs:text-[15px] 3xs:text-[14px] 2xs:w-[130px] 3xs:w-[112px] 4xs:w-[107px] whitespace-nowrap overflow-hidden w-[150px] md:w-[175px]'>{Turtles.attributes[3].value}</p>
                                                <p className='lg:text-[18px] md:text-[17px] 2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] px-[5px] my-[2px] bg-[#c4864c] rounded-2xl text-center w-[110px] 2xs:w-[95px] 3xs:w-[85px] 4xs:w-[81px]' style={{color:attribt2Color}}>{attribt2}</p>
                                            </div>
                                            <div className='flex flex-row'>
                                                <p className='lg:text-[18px] md:text-[17px] mr-[5px] 2xs:mr-[3px] 3xs:mr-[2px] 2xs:text-[15px] 3xs:text-[14px] 2xs:w-[130px] 3xs:w-[112px] 4xs:w-[107px] whitespace-nowrap overflow-hidden w-[150px] md:w-[175px]'>{Turtles.attributes[4].value}</p>
                                                <p className='lg:text-[18px] md:text-[17px] 2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] px-[5px] my-[2px] bg-[#c4864c] rounded-2xl text-center w-[110px] 2xs:w-[95px] 3xs:w-[85px] 4xs:w-[81px]' style={{color:attribt3Color}}>{attribt3}</p>
                                            </div>
                                            <div className='flex flex-row'>
                                                <p className='lg:text-[18px] md:text-[17px] mr-[5px] 2xs:mr-[3px] 3xs:mr-[2px] 2xs:text-[15px] 3xs:text-[14px] 2xs:w-[130px] 3xs:w-[112px] 4xs:w-[107px] whitespace-nowrap overflow-hidden w-[150px] md:w-[175px]'>{Turtles.attributes[6].value}</p>
                                                <p className='lg:text-[18px] md:text-[17px] 2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] px-[5px] my-[2px] bg-[#c4864c] rounded-2xl text-center w-[110px] 2xs:w-[95px] 3xs:w-[85px] 4xs:w-[81px]' style={{color:attribt4Color}}>{attribt4}</p>
                                            </div>
                                            <div className='flex flex-row'>
                                                <p className='lg:text-[18px] md:text-[17px] mr-[5px] 2xs:mr-[3px] 3xs:mr-[2px] 2xs:text-[15px] 3xs:text-[14px] 2xs:w-[130px] 3xs:w-[112px] 4xs:w-[107px] whitespace-nowrap overflow-hidden w-[150px] md:w-[175px]'>{Turtles.attributes[5].value}</p>
                                                <p className='lg:text-[18px] md:text-[17px] 2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] px-[5px] my-[2px] bg-[#c4864c] rounded-2xl text-center w-[110px] 2xs:w-[95px] 3xs:w-[85px] 4xs:w-[81px]' style={{color:attribt5Color}}>{attribt5}</p>
                                            </div>
                                            <div className='flex flex-row'>
                                                <p className='lg:text-[18px] md:text-[17px] mr-[5px] 2xs:mr-[3px] 3xs:mr-[2px] 2xs:text-[15px] 3xs:text-[14px] 2xs:w-[130px] 3xs:w-[112px] 4xs:w-[107px] whitespace-nowrap overflow-hidden w-[150px] md:w-[175px]'>{Turtles.attributes[7].value}</p>
                                                <p className='lg:text-[18px] md:text-[17px] 2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] px-[5px] my-[2px] bg-[#c4864c] rounded-2xl text-center w-[110px] 2xs:w-[95px] 3xs:w-[85px] 4xs:w-[81px]' style={{color:attribt6Color}}>{attribt6}</p>
                                            </div>
                                            <div className='flex flex-row'>
                                                <p className='lg:text-[18px] md:text-[17px] mr-[5px] 2xs:mr-[3px] 3xs:mr-[2px] 2xs:text-[15px] 3xs:text-[14px] 2xs:w-[130px] 3xs:w-[112px] 4xs:w-[107px] w-[150px] md:w-[175px]'>{Turtles.attributes[8].value}</p>
                                                <p className='lg:text-[18px] md:text-[17px] 2xs:text-[15px] 3xs:text-[14px] 4xs:text-[13.5px] w-[110px] 2xs:w-[95px] 3xs:w-[85px] 4xs:w-[81px]'></p>
                                            </div>
                                        </div>
                                    </div>
                                    ) : ( <></> )
                                    }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TurtleRenderer;