import { useEffect, useState } from 'react';
import shelllogo from '../assets/images/shellcoin.png';
import { dbTwo } from '../firebase';
import { ref, get } from 'firebase/database';
import { useSelector } from 'react-redux';

const ShlBalanceCount = () => {
    const blockchain = useSelector((state) => state.blockchain);

    // shl balance
    const [SHLBALANCE, setSHLBALANCE] = useState('?')
    function getSHLBalance() {
        fetch(`https://explorer.energyweb.org/api?module=account&action=tokenbalance&contractaddress=0xc6c6239614723298591f16bB2f779C9199b5AB1a&address=${blockchain.account}`)
        .then((response) => response.json())
        .then((data) => setSHLBALANCE((parseInt(data["result"])/1e+18).toFixed(0)));
    }

    // Get and update the data variables on every change of connected address
    useEffect(() => {
        getSHLBalance();
    }, [blockchain.account]);

    const [shlCirculatingSupply, setShlCirculatingSupply] = useState('?')

    async function getSHLSupplyData() {
        let shlcirculating = await get(ref(dbTwo, '/' + 'SHLcirculatingSupply'));
        shlcirculating = shlcirculating["_node"]["value_"];
        shlcirculating = Number(shlcirculating);
        shlcirculating = shlcirculating.toFixed(0);
        shlcirculating = shlcirculating.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
        setShlCirculatingSupply(shlcirculating);
    }

    useEffect(() => {
        getSHLSupplyData();
    }, [])

    return (
        <div className='py-1 mx-auto flex w-[33.0%] md:w-[30%] lg:w-[28%] xl:w-[24%] 2xl:w-[20%] rounded-md sm:rounded-lg bg-[#b8d7ff] transition-all'>
            <img className='ml-2 w-[30px] 2xs:w-[27px] 2xs:h-[27px] my-auto' src={shelllogo} alt="" />
            <h1 className='ml-2 items-center pt-[3px] font-bold flex justify-center text-base lg:text-lg xl:text-xl 2xl:text-2xl md:h-[32px] lg:h-[36px]'>{<abbr title={'Circulating supply: '+shlCirculatingSupply+' SHL'}>{SHLBALANCE}</abbr>}</h1>
        </div>
    );
}

export default ShlBalanceCount;